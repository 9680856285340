import { ElementRef, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import { URLConstants } from '../utility/url-constants';
import {
  IExcelTableData,
  INotesDetail,
  INotesSectionData,
  SectionObject,
  SvgIconProp,
  TableColumns,
} from '../models/dynamic.model';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ActionIcons,
  dateTimeFormat,
  HttpSignatures,
  LabelsAndPlaceHolders,
  MasterLookup,
  ModalPopupMessages,
  NotesType,
  pageType,
  ScrollData,
} from '../utility/constants';
import {
  AddCustomerNotes,
  AddIBCNotes,
  DeleteCustomerNotes,
  DeleteIBCNotes,
  UpdateCustomerNotes,
  UpdateIBCNotes,
} from '../components/notes/store/notes.action';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RowSelectionChangeParams } from 'src/app/features/model/manifest.model';
import { DatePipe } from '@angular/common';
import { DownloadFileService } from './download-file.service';
import { ValidatorPattern } from '../utility/validators.pattern';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public commoditySelectedData = new BehaviorSubject<any>([]);
  public savedBagData = new BehaviorSubject<any>({});
  public clearSelectionInputValue = new BehaviorSubject<any>('');
  public formPatchback = new BehaviorSubject<any>('');
  public criteriaInvalid = new BehaviorSubject<any>(false);
  public miscellaneousInvalid = new BehaviorSubject<any>(false);
  public getPrevSelectedMode = new BehaviorSubject<string>('');
  public getPrevPageMode = new BehaviorSubject<any>('');
  public pieceSelectedData = new BehaviorSubject<any>([]);
  public isPopupOpen = new BehaviorSubject<boolean>(false);
  public delete = new BehaviorSubject<any>([]);
  public isCheckForMode = new BehaviorSubject<any>('');
  public isBagPageMode = new BehaviorSubject<any>('');
  public checkAccordionDirtyData = new BehaviorSubject<boolean>(true);
  public checkValidDirtyData = new BehaviorSubject<boolean>(true);
  public checkAdvanceSearchFieldDirty = new BehaviorSubject<boolean>(false);
  public checkAccordionPieceDirtyData = new BehaviorSubject<boolean>(true);
  public checkAccordionCommodityDirtyData = new BehaviorSubject<boolean>(true);
  public checkOverlayOpened = new BehaviorSubject<boolean>(false);
  public overlayRefData = new BehaviorSubject<any>('');
  public mockFlag = false;
  public idData = new BehaviorSubject<any>('');
  public isMenuExpanded = new BehaviorSubject<boolean>(false);
  public getManifestPreviousMode = new BehaviorSubject<string>('');
  public isPreviousPage = new BehaviorSubject<string>('');
  public isflightDelete = new BehaviorSubject<string>('');
  public patchSearchText = new BehaviorSubject<string>('');
  private preventFurtherTypingSubscription: Subscription | null = null;
  private $subscription: Subscription | undefined;
  private destroy$: Subject<void> = new Subject<void>();
  public paginationData = new BehaviorSubject<any>({});
  public paginationData$ = this.paginationData
    .asObservable()
    .pipe(takeUntil(this.destroy$));
  public getHawbIdFromHawbInformation = new BehaviorSubject<any>(null);
  public checkForPieceCommodityAttachmentChanges = new BehaviorSubject<any>(false);
  public fetchAdvanceSearchData = new BehaviorSubject<any>('');

  constructor(
    public baseService: BaseService,
    private store: Store,
    private http: HttpClient,
    private fileService: DownloadFileService,
    private datePipe: DatePipe
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public commodityDetails(data: any) {
    this.commoditySelectedData.next(data);
  }

  public isDataSaved(data: any) {
    this.savedBagData.next(data);
  }

  public isCriteriaData(data: boolean) {
    this.criteriaInvalid.next(data);
  }

  public makeMiscellaneousDirty(data: boolean) {
    this.miscellaneousInvalid.next(data);
  }

  public setMode(data: any) {
    this.isCheckForMode.next(data);
  }

  public setBagPageMode(data: string) {
    this.isBagPageMode.next(data);
  }

  public getBagPageMode(data: string) {
    this.getPrevPageMode.next(data);
  }

  public getMode(data: string) {
    this.getPrevSelectedMode.next(data);
  }

  public getModeForManifest(data: string) {
    this.getManifestPreviousMode.next(data);
  }

  public isanyPopupOpen(data: boolean) {
    this.isPopupOpen.next(data);
  }

  public isValidField(field: string | number) {
    return field !== '' && field !== null && field !== undefined;
  }

  public getCountriesData(): Observable<any> {
    const url = URLConstants.getCountryDetails;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public getStateData(data: any): Observable<any> {
    const url = URLConstants.getStateDetails + data;
    return this.baseService.initiateApiRequest('GET', url, data, 'json');
  }

  public pieceDetails(data: any) {
    this.pieceSelectedData.next(data);
  }

  public getMasterLookUp(data: string): Observable<any> {
    const url = URLConstants.requestType + data;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public getIconsForInfoMode(
    mode: string,
    hawbInfoHeaderIcons: SvgIconProp[]
  ): SvgIconProp[] {
    const filtered: SvgIconProp[] = [];
    if (mode === 'View') {
      hawbInfoHeaderIcons.forEach((val: SvgIconProp) => {
        if (val.order === 2 || val.order === 4) {
          filtered.push(val);
        }
      });
      filtered.forEach((icon: SvgIconProp) => {
        if (icon.order === 2 || icon.order === 4) {
          icon.isDisabled = false;
        }
      });
    }
    if (mode === 'Edit') {
      hawbInfoHeaderIcons.forEach((val: SvgIconProp) => {
        if (val.order === 1 || val.order === 3 || val.order === 4) {
          filtered.push(val);
        }
      });
      filtered.forEach((icon: SvgIconProp) => {
        if (icon.order === 1 || icon.order === 3 || icon.order === 4) {
          icon.isDisabled = false;
        }
      });
    }
    if (mode === 'MainView') {
      hawbInfoHeaderIcons.forEach((val: SvgIconProp) => {
        if (val.order === 2 || val.order === 4) {
          filtered.push(val);
        }
      });
      filtered.forEach((icon: SvgIconProp) => {
        if (icon.order === 2 || icon.order === 4) {
          icon.isDisabled = true;
        }
      });
    }
    return filtered;
  }

  public checkPristineAccordionData(data: any) {
    this.checkAccordionDirtyData.next(data);
  }

  public checkValidAccordionData(data: any) {
    this.checkValidDirtyData.next(data);
  }

  public checkPristinePieceAccordionData(data: any) {
    this.checkAccordionPieceDirtyData.next(data);
  }

  public setOverlayRefData(data: any) {
    this.overlayRefData.next(data);
  }

  public getDataId(data: any) {
    this.idData.next(data);
  }

  /* For Checking Control Error to show tooltip */
  public checkControlError(formName: FormGroup, controlName: string) {
    const form = formName;
    return form.get(controlName)?.touched && form.get(controlName)?.errors;
  }

  /* For setting width, event and enabling of tooltips for text fields */
  public setErrorTooltipData(
    event: MouseEvent,
    fieldName: string,
    setWidth: any,
    setTooltipEvent: any,
    setErrorTooltip: any,
    reduceWidth?: number,
    isFrom?: string
  ) {
    setWidth[fieldName] = this.getControlWidth(event, reduceWidth, isFrom);
    setTooltipEvent[fieldName] = event;
    setErrorTooltip[fieldName] = true;
  }

  /* For clearing of tooltips for text fields */
  public closeErrorTooltip(controlName: string, setErrorTooltip: any) {
    setErrorTooltip[controlName] = false;
  }

  /* For getting width of text fields to show tooltip */
  public getControlWidth(
    event: MouseEvent,
    reduceWidth?: number,
    isFrom?: string
  ) {
    const target = event.target as HTMLElement;
    const parentElement =
      isFrom === pageType.piece
        ? target.parentElement?.parentNode?.parentNode?.parentElement
        : target.parentElement?.parentNode?.parentNode?.parentNode
            ?.parentElement;
    if (reduceWidth) {
      return (parentElement?.offsetWidth ?? 0) - reduceWidth + 'px';
    } else {
      return (
        (isFrom === 'address-name' ? 210 : parentElement?.offsetWidth) + 'px'
      );
    }
  }

  /* For setting width of text fields to show tooltip after sidebar expanded/collapsed */
  public setModifiedTooltipWidth(event: any, tooltip: any, width: any) {
    const openedControls = Object.keys(tooltip).filter(
      key => tooltip[key] === true
    );
    if (openedControls.length) {
      openedControls.forEach((control: string) => {
        width[control] = this.getControlWidth(event[control]);
      });
      return width;
    } else {
      return {};
    }
  }

  public checkPristineCommodityAccordionData(data: any) {
    this.checkAccordionCommodityDirtyData.next(data);
  }

  //notes tooltip for username and facility
  public notesTooltip(tooltipInfo: any): string {
    if (!tooltipInfo) return '';
    const userName = tooltipInfo.userName
      ? tooltipInfo.userName
      : tooltipInfo.type === 'customer'
        ? 'Customer'
        : 'IBC';
    const facility = tooltipInfo.facility || '';

    let tooltipContent = '';
    tooltipContent += `${userName}\n${facility}`;
    return tooltipContent;
  }

  // custom tooltip overlay
  public setOverlayOpen(data: any) {
    this.checkOverlayOpened.next(data);
  }

  // get notes by screen - starts
  public filterNotesDatabyScreen(
    data: INotesSectionData,
    isFromScreen: string
  ) {
    const ibcBag = data.ibc.filter(
      (note: any) =>
        note.isFromScreen === isFromScreen && note.action !== 'delete'
    );
    const customerBag = data.customer.filter(
      (note: any) =>
        note.isFromScreen === isFromScreen && note.action !== 'delete'
    );
    const count = customerBag.length + ibcBag.length;
    return count > 0 ? `(${customerBag.length + ibcBag.length})` : '';
  }
  // get notes by screen - ends

  //update notes after save for commodity & piece information
  public updateNotesCommodityPiece(pageData: any) {
    const ibcNotes = pageData.notes.ibc;
    const customerNotes = pageData.notes.customer;
    if (ibcNotes && ibcNotes.length > 0) {
      ibcNotes.forEach((ibcNote: INotesDetail) => {
        this.store.dispatch(new UpdateIBCNotes(ibcNote));
      });
    }
    if (customerNotes && customerNotes.length > 0) {
      customerNotes.forEach((customerNote: INotesDetail) => {
        this.store.dispatch(new UpdateCustomerNotes(customerNote));
      });
    }
  }

  // remove unsaved notes from commodity information and piece information
  public removeUnsavedNotes(dataForRemoval: any[]) {
    dataForRemoval.forEach((note: INotesDetail) => {
      if (!note.isSaved) {
        note.type === NotesType.ibc
          ? this.store.dispatch(new DeleteIBCNotes(note))
          : this.store.dispatch(new DeleteCustomerNotes(note));
      }
    });
  }

  //update notes data to store - starts
  public updateNotes(
    data: any,
    moduleName: string,
    subModuleName: string,
    otherSubmodule: string
  ) {
    const mainNotes = { ibc: data.notes.ibc, customer: data.notes.customer };
    this.dispatchPieceCommodityNotesForStore(mainNotes, moduleName);
    if (subModuleName) {
      this.dispatchPieceCommodityNotesForStore(
        data.pieceList ? data.pieceList : data.flights,
        subModuleName
      );
    }
    if (otherSubmodule) {
      this.dispatchPieceCommodityNotesForStore(
        data.commodityList,
        otherSubmodule
      );
    }
  }

  public dispatchPieceCommodityNotesForStore(
    incomingData: any,
    isFrom: string
  ) {
    const allIBCNotes: any[] = [];
    const allCustomerNotes: any[] = [];
    if (
      isFrom === pageType.bag ||
      isFrom === pageType.hawb ||
      isFrom === pageType.manifest ||
      isFrom === pageType.mawb
    ) {
      this.getIbcCustomerNotes(
        incomingData,
        null,
        allIBCNotes,
        allCustomerNotes,
        isFrom,
        true
      );
    } else {
      this.notesDataIteration(
        incomingData,
        isFrom,
        allIBCNotes,
        allCustomerNotes
      );
    }
    this.updateNotesStore(allIBCNotes, allCustomerNotes);
  }

  private notesDataIteration(
    incomingData: any,
    isFrom: string,
    allIBCNotes: any[],
    allCustomerNotes: any[]
  ) {
    incomingData.forEach((item: any) => {
      let notesId;
      if (isFrom === pageType.piece || isFrom === pageType.bagPiece) {
        notesId = item.piece.id;
      } else if (isFrom === pageType.mawbFlight) {
        notesId = item.flightId;
      } else {
        notesId = item.commodity.id;
      }
      const notesData = item.notes;
      this.getIbcCustomerNotes(
        notesData,
        notesId,
        allIBCNotes,
        allCustomerNotes,
        isFrom,
        true
      );
    });
  }

  private getIbcCustomerNotes(
    notesData: any,
    notesId: any,
    allIBCNotes: any[],
    allCustomerNotes: any[],
    isFrom: string,
    isSavedData: boolean
  ) {
    if (notesData?.ibc) {
      allIBCNotes.push(
        ...this.processNotes(
          notesData.ibc,
          isFrom,
          notesId,
          NotesType.ibc,
          isSavedData
        )
      );
    }
    if (notesData?.customer) {
      allCustomerNotes.push(
        ...this.processNotes(
          notesData.customer,
          isFrom,
          notesId,
          NotesType.customer,
          isSavedData
        )
      );
    }
  }

  private processNotes(
    notes: INotesDetail[],
    isFromScreen: string,
    notesId: any,
    type: string,
    isSavedData: boolean
  ) {
    return notes.map((note: INotesDetail) => ({
      ...note,
      isFromScreen,
      notesId,
      source: 'API',
      action: 'c',
      type,
      isEditable: false,
      isDisable: false,
      isSaved: isSavedData,
    }));
  }

  private updateNotesStore(allIBCNotes: any[], allCustomerNotes: any[]) {
    if (allIBCNotes && allIBCNotes.length > 0) {
      allIBCNotes.forEach(ibcNote => {
        this.store.dispatch(new AddIBCNotes(ibcNote));
      });
    }
    if (allCustomerNotes && allCustomerNotes.length > 0) {
      allCustomerNotes.forEach(customerNote => {
        this.store.dispatch(new AddCustomerNotes(customerNote));
      });
    }
  }
  //update notes data to store - ends

  //remove unmodified notes - starts
  public noModificationOnNotes(notesData: any) {
    const idForRemoval: any[] = [];
    notesData.forEach((note: any) => {
      if (note.action === 'c' && note.source === 'API') {
        idForRemoval.push(note.id);
      }
    });
    idForRemoval.forEach((note: any) => {
      const removeIndex = notesData.findIndex((obj: any) => obj.id === note);
      if (removeIndex != -1) {
        notesData.splice(removeIndex, 1);
      }
    });
  }
  //remove unmodified notes - ends

  public attachmentSignedUrl(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'PUT',
      URLConstants.signedurl,
      data
    );
  }

  public deleteAttachment(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.deleteAttachment,
      data
    );
  }

  public AddAttachmentData(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.addAttachment,
      data,
      'json'
    );
  }

  public attachmentStoredSignedUrl(data: any, fileName: any): Observable<any> {
    return this.baseService.uploadFile(data.url, fileName);
  }

  // get side bar expansion value to make tooltip reposition
  public getMenuExpanded(data: any) {
    this.isMenuExpanded.next(data);
  }

  // Comparison Error Logic for origin-destination and min-max values
  public comparisonError(
    value1: any,
    value2: any,
    controlName1: string,
    isFromControlName: string,
    isTextComparison: boolean,
    formGroup: FormGroup, // Pass the form group as a parameter
    controlName2: string // The second control's name
  ) {
    const isBothValid = this.isValidField(value1) && this.isValidField(value2);
    const validCheck = isTextComparison
      ? isBothValid
      : isBothValid && value1 !== 0 && value2 !== 0;

    let condition = false;

    if (isTextComparison) {
      condition = value1.toLowerCase() === value2.toLowerCase();
    } else {
      condition = value1 > value2;
    }

    if (validCheck && condition) {
      if (isFromControlName === controlName1) {
        formGroup.get(controlName1)?.setErrors({ comparisonError: true });
        this.clearFieldError(
          formGroup,
          controlName2,
          MasterLookup.comparisonError
        );
        return {
          control1Error: true,
          control2Error: false,
        };
      } else {
        formGroup.get(controlName2)?.setErrors({ comparisonError: true });
        this.clearFieldError(
          formGroup,
          controlName1,
          MasterLookup.comparisonError
        );
        return {
          control1Error: false,
          control2Error: true,
        };
      }
    } else {
      this.clearFieldError(
        formGroup,
        controlName1,
        MasterLookup.comparisonError
      );
      this.clearFieldError(
        formGroup,
        controlName2,
        MasterLookup.comparisonError
      );
      return {
        control1Error: false,
        control2Error: false,
      };
    }
  }

  // get id value for autocomplete dropdowns
  public getAutocompleteDropdownId(
    dropdownResponse: any,
    formName: any,
    controlName: string,
    apiControlName: string,
    isForName?: boolean
  ) {
    // if block is for patching names to control by id(got from api)
    if (isForName) {
      const form = formName;
      const matchingData = dropdownResponse?.find(
        (item: any) => item.id === form.get(apiControlName)?.value
      );
      if (matchingData) {
        form.get(controlName)?.setValue(matchingData.name);
      }
    } else {
      this.getAutocompleteDropdownName(
        dropdownResponse,
        formName,
        controlName,
        apiControlName,
        'name'
      );
    }
  }

  public getAutocompleteDropdownIdParam(
    dropdownResponse: any,
    formName: any,
    controlName: string,
    apiControlName: string
  ) {
    this.getAutocompleteDropdownName(
      dropdownResponse,
      formName,
      controlName,
      apiControlName,
      'id'
    );
  }

  public getAutocompleteDropdownBinding(
    dropdownResponse: any,
    formName: any,
    controlName: string,
    apiControlName: string,
    param: any
  ) {
    const form = formName;
    const matchingData = dropdownResponse.find(
      (item: any) => item[param] === form.get(controlName)?.value
    );
    if (matchingData) {
      form.get(apiControlName)?.setValue(matchingData.id);
      form.get(controlName)?.setErrors({ autocompleteError: false });
      delete form.get(controlName)?.errors?.['autocompleteError'];
      const controlErrors = form.get(controlName)?.errors;
      if (controlErrors && Object.keys(controlErrors).length === 0) {
        form.get(controlName)?.setErrors(null);
      }
    } else {
      form.get(controlName)?.setErrors({ autocompleteError: true });
    }
  }

  public getAutocompleteDropdownName(
    dropdownResponse: any[],
    formName: FormGroup,
    controlName: string,
    apiControlName: string,
    param: string
  ) {
    // Check if dropdownResponse is defined and is an array
    if (!Array.isArray(dropdownResponse)) {
      return; // Return early if dropdownResponse is not an array
    }
    const form = formName;
    let controlValue: string | null = form.get(controlName)?.value;
    if (!controlValue) {
      form.get(apiControlName)?.setValue(''); // No need to process if the control value is empty
    } else {
      controlValue = controlValue.replace(/\s/g, ''); // Remove spaces from control value
      let exactMatch: any = null;
      const potentialMatches: any[] = [];
      // Single iteration to find exact match and potential matches
      dropdownResponse.forEach((item: any) => {
        const dropdownOption = item[param].replace(/\s/g, ''); // Remove spaces from dropdown option
        if (dropdownOption.toLowerCase() === controlValue?.toLowerCase()) {
          exactMatch = item;
        }
        if (
          dropdownOption.toLowerCase().includes(controlValue?.toLowerCase())
        ) {
          potentialMatches.push(item);
        }
      });
      if (exactMatch) {
        // Set the value of the apiControlName field with the exact match's id
        form.get(apiControlName)?.setValue(exactMatch.id);
        // Set the control value to the exact match's name
        form.get(controlName)?.patchValue(exactMatch[param]);
        // Clear autocomplete errors for the control
        form.get(controlName)?.setErrors({ autocompleteError: false });
        delete form.get(controlName)?.errors?.['autocompleteError'];
        const controlErrors = form.get(controlName)?.errors;
        if (controlErrors && Object.keys(controlErrors).length === 0) {
          form.get(controlName)?.setErrors(null);
        }

        // Add logic to prevent further typing
        this.preventFurtherTyping(form, controlName, exactMatch[param]);
      } else if (potentialMatches.length === 1) {
        // If there's a single potential match, patch the control value with the matching item value
        form.get(controlName)?.patchValue(potentialMatches[0][param]);
        // Also set the value of the apiControlName field
        form.get(apiControlName)?.setValue(potentialMatches[0].id);
        // Clear autocomplete errors for the control
        form.get(controlName)?.setErrors({ autocompleteError: false });
        delete form.get(controlName)?.errors?.['autocompleteError'];
        const controlErrors = form.get(controlName)?.errors;
        if (controlErrors && Object.keys(controlErrors).length === 0) {
          form.get(controlName)?.setErrors(null);

          // Add logic to prevent further typing
          this.preventFurtherTyping(
            form,
            controlName,
            potentialMatches[0][param]
          );
        }
      } else {
        // Set autocomplete error if no match is found
        form.get(controlName)?.setErrors({ autocompleteError: true });
      }
    }
  }

  private preventFurtherTyping(
    form: FormGroup,
    controlName: string,
    exactMatchName: string
  ) {
    const control = form.get(controlName);
    if (control) {
      // Unsubscribe the previous subscription if exists
      if (this.preventFurtherTypingSubscription) {
        this.preventFurtherTypingSubscription?.unsubscribe();
      }
      // Listen for changes in the control value
      this.preventFurtherTypingSubscription = control.valueChanges.subscribe(
        value => {
          if (value !== exactMatchName) {
            const lastCharacter = value?.slice(-1);
            const allowedKeys = ['Backspace', 'Delete'];
            if (lastCharacter && !allowedKeys.includes(lastCharacter)) {
              control.setValue(exactMatchName, { emitEvent: false });
            } else if (value === '') {
              // Remove the exact match name if the field is cleared
              this.preventFurtherTypingSubscription?.unsubscribe();
              this.preventFurtherTypingSubscription = null;
            }
          }
        }
      );
    }
  }

  public downloadAttachment(data: string): Observable<any> {
    const url = URLConstants.downloadAttachmnet + data;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  onChangePage(event: any, pagingProp: any, getData: () => void) {
    pagingProp.fromIndex = event.pageIndex * event?.pageSize + 1;
    pagingProp.toIndex = pagingProp.fromIndex + (event?.pageSize - 1);
    getData();
  }

  isRowSelectionChange(params: RowSelectionChangeParams) {
    const {
      event,
      rowData,
      selection,
      totalData,
      getSummaryPageIconsWithState,
      manifestEnableView,
    } = params;
    if (event.target.checked) {
      params.selectedCount++;
      selection.select(rowData);
      if (params.selectedCount === totalData) {
        params.isAllCheckboxSelected = true;
      }
    } else {
      params.selectedCount--;
      selection.deselect(rowData);
      params.isAllCheckboxSelected = false;
    }
    if (selection.selected.length > 0) {
      getSummaryPageIconsWithState('Selected', true);
    } else {
      manifestEnableView(false);
    }
    return {
      selectedCount: params.selectedCount,
      isAllCheckboxSelected: params.isAllCheckboxSelected,
    };
  }

  handleCheckboxSelection(
    index: number,
    isSelected: boolean,
    gridColumns: any[],
    displayedColumns: string[],
    defaultGridDisplayColumns: string[]
  ) {
    if (!isSelected) {
      gridColumns[index].isSelected = true;
      displayedColumns.splice(0, 1); // to remove 'select' from array
      displayedColumns.splice(displayedColumns.length - 1, 1); // to remove 'action' from array
      displayedColumns.splice(index, 0, gridColumns[index].value);
      displayedColumns.unshift('select'); // to add 'select' as 1st position in array
      displayedColumns.push('action'); // to add 'action' as last position in array
      displayedColumns.sort(
        (a, b) =>
          defaultGridDisplayColumns.indexOf(a) -
          defaultGridDisplayColumns.indexOf(b)
      );
    } else {
      gridColumns[index].isSelected = false;
    }
  }

  public filterAndModifyIcons(
    icons: SvgIconProp[],
    condition: boolean
  ): SvgIconProp[] {
    const filtered = icons.filter((val: SvgIconProp) =>
      [
        ActionIcons.add,
        ActionIcons.view,
        ActionIcons.downloadFile,
        ActionIcons.print,
      ].includes(val.name)
    );

    return filtered.map((val: SvgIconProp) => {
      val.isDisabled = condition;
      return val;
    });
  }

  public setGridDisplayColumns(
    event: CdkDragDrop<string[]>,
    displayedColumns: string[],
    gridColumnConfig: TableColumns[],
    storageName: string
  ) {
    moveItemInArray(
      displayedColumns,
      event.previousIndex + 1,
      event.currentIndex + 1
    ); // in built method to reorder columns
    const unselectedColumns = gridColumnConfig.filter(
      column => !displayedColumns.includes(column.key)
    ); // filter out unselected columns
    gridColumnConfig = gridColumnConfig.filter(column =>
      displayedColumns.includes(column.key)
    );
    gridColumnConfig.sort(
      (a, b) =>
        displayedColumns.indexOf(a.key) - displayedColumns.indexOf(b.key)
    );
    gridColumnConfig.push(...unselectedColumns); // after sort adding unselected column at last
    const columnsOrderToSave = gridColumnConfig.map(obj => obj.key);
    columnsOrderToSave.unshift('select'); // to add 'select' as 1st position in array
    columnsOrderToSave.push('action'); // to add 'action' as last position in array
    localStorage.setItem(storageName, JSON.stringify(columnsOrderToSave));
    return gridColumnConfig;
  }

  public scrollToTop(elementRef: ElementRef): void {
    try {
      elementRef.nativeElement.scrollTop =
        elementRef.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  public setupFormIcons(
    formGroup: FormGroup,
    icons: any[],
    updateIconsState: (
      hasChanges: boolean,
      hasErrors: boolean,
      hasAnyValue: boolean
    ) => void
  ): void {
    const initialValue = formGroup.value;

    // Unsubscribe previous subscription if exists
    if (this.$subscription) {
      this.$subscription.unsubscribe();
    }

    // Combine valueChanges and statusChanges into a single subscription
    this.$subscription = combineLatest([
      formGroup.valueChanges,
      formGroup.statusChanges,
    ])
      .pipe(takeUntil(this.destroy$)) // Using destroy$ to manage subscription
      .subscribe(([value, status]: [any, any]) => {
        const hasChanges = this.checkForFormChanges(initialValue, value);
        const hasErrors = formGroup.invalid;
        const hasAnyValue = this.checkForAnyValue(value);
        updateIconsState(hasChanges, hasErrors, hasAnyValue);
      });
  }

  private checkForFormChanges(initialValue: any, currentValue: any): boolean {
    return Object.keys(initialValue).some(
      key => currentValue[key] !== initialValue[key] && currentValue[key] !== ''
    );
  }

  private checkForAnyValue(value: any): boolean {
    return Object.values(value).some(val => val !== '' && val !== null);
  }

  // Optional: Implement a method to clean up subscriptions
  public unsubscribe(): void {
    if (this.$subscription) {
      this.$subscription.unsubscribe();
    }
  }

  public validateAndShowPopup(
    form: FormGroup,
    controlName: string,
    modalService: any,
    messageComponent: any,
    message: string
  ) {
    const control = form.get(controlName);
    if (control?.value && control.errors?.['autocompleteError']) {
      modalService.openPopup(
        {
          message: message,
          isFooterRequired: false,
        },
        ModalPopupMessages.Warning,
        messageComponent,
        '360px'
      );
    }
  }

  public scrollInfoToTop(element: ElementRef, smooth = true) {
    element.nativeElement.scrollTo({
      top: 0,
      behavior: smooth ? ScrollData.smooth : ScrollData.auto,
    });
  }

  public scrollInfoToBottom(element: ElementRef, smooth = true) {
    element.nativeElement.scrollTo({
      top: element.nativeElement.scrollHeight,
      behavior: smooth ? ScrollData.smooth : ScrollData.auto,
    });
  }

  public checkScrollPosition(element: ElementRef): boolean {
    const scrollPosition = element.nativeElement.scrollTop;
    const containerHeight = element.nativeElement.clientHeight;
    const contentHeight = element.nativeElement.scrollHeight;
    return scrollPosition + containerHeight >= contentHeight - 1;
  }

  public clearInputValue(data: any) {
    this.clearSelectionInputValue.next(data);
  }

  public bindBackValue(data: any) {
    this.formPatchback.next(data);
  }

  public setRequiredDataForDownload(
    name: string,
    headers: string[],
    columns: string[],
    fileType: string,
    successMessage: string,
    data: any
  ) {
    const excelData: IExcelTableData = {
      name: name,
      headers: headers,
      headerKeys: Object.keys(data[0]).sort(
        (a, b) => columns.indexOf(a) - columns.indexOf(b)
      ),
      tableData: data,
      selectedFileType: fileType,
      successMessage: successMessage,
      isFromSummary: true,
    };
    this.fileService.generateExcelCSVFile(excelData);
  }

  public getPreviousPage(data: string) {
    this.isPreviousPage.next(data);
  }

  public setData(data: any): void {
    this.paginationData.next(data);
  }

  public afterFlightDelete(data: string) {
    this.isflightDelete.next(data);
  }

  public setSearchText(data: string) {
    this.patchSearchText.next(data);
  }

  public clearFieldError(form: FormGroup, fieldName: string, errorKey: string) {
    const control = form.get(fieldName);
    if (control?.hasError(errorKey)) {
      delete control.errors?.[errorKey];
      control.updateValueAndValidity();
    }
  }

  public getDataForDownloadSharedTabsInfoPage(allData: any) {
    const sharedTabs: Record<string, SectionObject> = {
      notes: this.getSectionObject(
        this.filteredNotesDataForDownloadFile(allData.notes),
        [],
        Object.keys(
          this.filteredNotesDataForDownloadFile(allData.notes)?.[0] ?? []
        )
      ),
      events: this.getSectionObject([], [], []), // events are empty here
      attachments: this.getSectionObject(
        this.filterRequiredDataForAttachmentsDownloadFile(
          allData.attachmentList ? allData.attachmentList : allData.attachments
        ),
        [],
        Object.keys(
          this.filterRequiredDataForAttachmentsDownloadFile(
            allData.attachmentList
              ? allData.attachmentList
              : allData.attachments
          )?.[0] ?? []
        )
      ),
    };
    // Filter out empty sections
    return Object.keys(sharedTabs).reduce(
      (result: Record<string, SectionObject>, key: string) => {
        if (sharedTabs[key]?.data?.length > 0) {
          result[key] = sharedTabs[key];
        }
        return result;
      },
      {}
    );
  }

  public filteredNotesDataForDownloadFile(incomingNotesData: any) {
    incomingNotesData.ibc.forEach((note: any) => {
      note.type = 'IBC';
    });
    incomingNotesData.customer.forEach((note: any) => {
      note.type = 'Customer';
    });
    const allNotes = [...incomingNotesData.ibc, ...incomingNotesData.customer];
    return allNotes.map((note: any) => {
      return {
        note: note?.comments ?? '',
        noteType: note?.type ?? '',
        createdBy: note?.userName ?? '',
        dateAndTime:
          this.datePipe.transform(
            note?.date,
            dateTimeFormat.dateTime24hoursWithSpace
          ) ?? '',
      };
    });
  }

  public filterRequiredDataForAttachmentsDownloadFile(
    incomingAttachmentsData: any
  ) {
    return incomingAttachmentsData.map((note: any) => {
      return {
        attachmentType: note?.fileType ?? '',
        fileName: note?.fileName ?? '',
        description: note?.description ?? '',
        createdBy: note?.userName ?? note?.username ?? '',
        dateAndTime:
          this.datePipe.transform(
            note?.createdDate,
            dateTimeFormat.dateTime24hoursWithSpace
          ) ??
          this.datePipe.transform(
            note?.date,
            dateTimeFormat.dateTime24hoursWithSpace
          ) ??
          '',
      };
    });
  }

  public getSectionObject(
    incomingData: any,
    incomingHeader: string[],
    incomingHeaderKeys: string[]
  ) {
    return {
      data: incomingData,
      headers: incomingHeader,
      headerKeys: incomingHeaderKeys,
    };
  }

  public validateFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      }
    });
  }

  public setControlRequiredValidation(form: any, controlName: string) {
    const formName = form;
    formName.get(controlName)?.addValidators(Validators.required);
    formName.get(controlName)?.updateValueAndValidity();
  }

  public removeControlRequiredValidation(form: any, controlName: string) {
    const formName = form;
    formName.get(controlName)?.removeValidators(Validators.required);
    formName.get(controlName)?.updateValueAndValidity();
  }

  public clearControlValidators(form: any, controlName: string) {
    const formName = form;
    formName.get(controlName)?.clearValidators();
    formName.get(controlName)?.updateValueAndValidity();
  }

  public setPhoneControlValidation(
    form: any,
    controlName: string,
    isSpecificFormat: boolean
  ) {
    const formName = form;
    if (isSpecificFormat) {
      formName
        .get(controlName)
        ?.setValidators([
          Validators.pattern(ValidatorPattern.NANPPhoneValidation),
          Validators.minLength(12),
        ]);
    } else {
      formName
        .get(controlName)
        ?.addValidators([
          Validators.minLength(10),
          Validators.pattern(ValidatorPattern.PhoneValidationWith10Numbers),
        ]);
    }
    formName.get(controlName)?.updateValueAndValidity();
  }

  public fileExtensionValidator(
    fileData: any,
    allowedExtensions: string[]
  ): ValidatorFn {
    return (control: AbstractControl) => {
      const fileName = control.value;
      if (fileName) {
        const fileExtension = fileData.name.split('.').pop()?.toLowerCase();
        if (allowedExtensions.includes(fileExtension || '')) {
          return null;
        } else {
          return { invalidExtension: true };
        }
      }
      return null;
    };
  }

  public fileSizeValidator(fileData: any, maxSizeMB: number): ValidatorFn {
    return (control: AbstractControl) => {
      const file = control.value as File | null;
      if (file) {
        const maxSizeBytes = maxSizeMB * 1024 * 1024;
        if (fileData.size > maxSizeBytes) {
          return { fileSize: true };
        }
      }
      return null;
    };
  }

  public setHawbIdFromHawbInformation(data: any) {
    this.getHawbIdFromHawbInformation.next(data);
  }

  public addDeleteAttachmentCheck(data: any) {
    this.checkForPieceCommodityAttachmentChanges.next(data);
  }

  public getPlaceholder(
    form: FormGroup,
    controlName: string,
    pageMode: string,
    modeIcons: any
  ): string {
    const isBulkEdit = pageMode === modeIcons.bulkEdit;
    const controlValue = form.get(controlName)?.value;

    if (isBulkEdit && controlValue === '') {
      return LabelsAndPlaceHolders.variousValues
    }
    return '';
  }

  public isadvanceSearchFieldDirty(data: any) {
    this.checkAdvanceSearchFieldDirty.next(data);
  }

  public getAdvanceSearchData(data: any) {
    this.fetchAdvanceSearchData.next(data);
  }
}
