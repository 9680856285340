<div class="app-layout-wrapper">
  <header class="header">
    <app-header></app-header>
  </header>
  <nav class="sidebar">
    <app-sidebar></app-sidebar>
  </nav>
  <section cdkScrollable class="main">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
    <footer class="footer">
      <app-footer></app-footer>
    </footer>
  </section>
</div>
