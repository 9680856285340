import { FormConfig } from 'src/app/shared/models/dynamic.model';
import { CriteriasComponent } from './bag-information/criterias/criterias.component';
import { MiscellaneousComponent } from './bag-information/miscellaneous/miscellaneous.component';
import {
  ComponentMapping,
  IBagAttachmentData,
  IBagCriteriaData,
  IBagInformationData,
  IBagLinkedPieceData,
  IBagMiscellaneousData,
  IBagNotesData,
  PieceGridColumnData,
  TableColumns,
  Widget,
} from '../../model/bag.model';
import { searchDataParam } from 'src/app/shared/utility/constants';

/* Default Widget Configuration */
export const widgets: Widget[] = [
  {
    id: 1,
    title: 'Total Weight',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'weight',
    name: 'totalWeight',
  },
  {
    id: 2,
    title: 'Number of Shipments',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'hawbNumbers',
    name: 'totalNoOfHawbs',
  },
  {
    id: 3,
    title: 'Number of Pieces',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'pieces',
    name: 'totalNoOfPieces',
  },
];

/* Bag Information Form Fields Configuration */
export const bagInfoFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'bagNumber',
      type: 'input',
      label: 'Bag',
      required: true,
      requiredError: 'Bag Ext is required',
      minValue: 10,
      maxValue: 11,
      minError: '10 characters is required',
    },
    {
      id: 'origin',
      type: 'input',
      label: 'Origin',
      required: true,
      requiredError: 'Origin is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destination',
      type: 'input',
      label: 'Destination',
      required: true,
      requiredError: 'Destination is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'packagingType',
      type: 'input',
      label: 'Packaging',
      required: true,
      requiredError: 'Packaging is required',
      minValue: 1,
      maxValue: 1,
    },
    {
      id: 'packagingTypeName',
      type: 'input',
      label: 'Packaging',
      required: true,
      requiredError: 'Packaging is required',
    },
    {
      id: 'manifestNumber',
      type: 'input',
      label: 'Manifest',
      isDisableField: true,
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight Number',
      isDisableField: true,
    },
    { id: 'airline', type: 'input', label: 'Airline', isDisableField: true },
    { id: 'bagId', type: 'input', label: 'Bag Id', isDisableField: true },
    { id: 'description', type: 'input', label: 'Description', maxValue: 1000 },
  ],
};

/* Component Mappings Configuration */
export const componentMappings: ComponentMapping[] = [
  { title: 'Criteria', componentType: CriteriasComponent },
  { title: 'Miscellaneous', componentType: MiscellaneousComponent },
  { title: 'Notes', componentType: '' },
  { title: 'Events', componentType: '' },
  { title: 'Attachments', componentType: '' },
  { title: 'Details', componentType: '' },
];

/* Criteria Form Fields Configuration */
export const criteriaFormfieldsConfig: FormConfig = {
  fields: [
    { id: 'agent', type: 'input', label: 'Agent', minValue: 1, maxValue: 126 },
    { id: 'minHawbValue', type: 'input', label: 'Minimum HAWB value($)' },
    { id: 'maxHawbValue', type: 'input', label: 'Maximum HAWB value ($)' },
    { id: 'minBagWeight', type: 'input', label: 'Minimum Bag weight(lb)' },
    { id: 'maxBagWeight', type: 'input', label: 'Maximum Bag weight(lb)' },
    { id: 'clearanceType', type: 'input', label: 'Clearance Type' },
    { id: 'clearanceTypeVal', type: 'input', label: 'Clearance Type Input' },
    { id: 'airlineId', type: 'input', label: 'Airline Code' },
    { id: 'airline', type: 'input', label: 'Airline ID' },
    { id: 'serviceTypeGroups', type: 'input', label: 'Service Type' },
    { id: 'custId', type: 'input', label: 'Customer ID' },
    { id: 'customer', type: 'input', label: 'Customer Name' },
    { id: 'objectGroups', type: 'input', label: 'Customer Group ID' },
    { id: 'finalFacility', type: 'input', label: 'Final Facility' },
    { id: 'hawbFinalFacility', type: 'input', label: 'Final Facility' },
    { id: 'agentTypeData', type: 'input', label: 'Agent Type Data' },
    { id: 'finalFacilityData', type: 'input', label: 'Facility Data' },
    { id: 'finalDestination', type: 'input', label: 'Destination' },
    { id: 'finalDestinationId', type: 'input', label: 'DestinationIdParam' },
    {
      id: 'serviceTypeGroupsVal',
      type: 'input type',
      label: 'Service Type Val',
    },
  ],
};

/* Miscellaneous Form Fields Configuration */
export const miscellaneousFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'tareWeight',
      type: 'input',
      label: 'Tare Weight(lb)',
    },
  ],
};

/* Piece Table Fields Configuration */
export const pieceTablefieldsConfig: FormConfig = {
  fields: [
    {
      id: 'pieceExt',
      type: 'input',
      label: 'Piece Ext',
      allowAlphaNumeric: true,
    },
  ],
};

/* Piece Grid Input Row Data Configuration */
export const pieceGridInputRowData: PieceGridColumnData[] = [
  {
    id: '',
    pieceExt: '',
    pieceNumber: '',
    weight: '',
    dimensions: '',
    packagingType: '',
    hawbExt: '',
    isInputRow: true,
  },
];

/* Search Form Fields Configuration */
export const searchFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'bagId',
      type: 'input',
      label: 'Bag',
      minValue: 1,
      maxValue: 11,
      allowAlphaNumeric: true,
    },
    {
      id: 'origin',
      type: 'input',
      label: 'Origin',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destination',
      type: 'input',
      label: 'Destination',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'package',
      type: 'input',
      label: 'Package',
      allowAlphabet: true,
      minValue: 1,
      maxValue: 50,
    },
    {
      id: 'manifest',
      type: 'input',
      label: 'Manifest',
      allowNumeric: true,
      maxValue: 14,
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight Number',
      minValue: 1,
      maxValue: 6,
    },
    { id: 'originData', type: 'input', label: 'Origin Data' },
    { id: 'packaging', type: 'input', label: 'Packaging Data' },
    { id: 'airline', type: 'input', label: 'Airline' },
  ],
};

/* Grid Summary Columns Configuration */
export const gridSummaryColumns: TableColumns[] = [
  ...new Set([
    {
      key: 'bagExt',
      label: 'Bag',
      value: 'bagExt',
      isSelected: true,
      defaultView: true,
    },
    {
      key: 'noofPieces',
      label: 'Pieces',
      isSelected: true,
      defaultView: true,
      value: 'noofPieces',
    },
    {
      key: 'weight',
      label: 'Weight (lb)',
      value: 'weight',
      isSelected: true,
      defaultView: true,
    },
    {
      key: 'manifestId',
      isSelected: true,
      label: 'Manifest',
      value: 'manifestId',
      defaultView: true,
    },
    {
      key: 'packagingType',
      value: 'packagingType',
      isSelected: true,
      defaultView: true,
      label: 'Packaging',
    },
  ]),
];

/* Piece Table Grid Columns Configuration */
export const pieceTableGridColumns = [
  { key: 'pieceExt', label: 'Piece' },
  { key: 'pieceNumber', label: 'Piece Number' },
  { key: 'weight', label: 'Weight (lb)' },
  { key: 'dimensions', label: 'Dimension L-W-H (inch)' },
  { key: 'packagingType', label: 'Packaging' },
  { key: 'hawbExt', label: 'Shipment' },
];

/* Bag Information - Default Values Configuration */
export const defaultBagNotesData = (): IBagNotesData => ({
  ibc: [],
  customer: [],
});

export const defaultBagAttachmentsData = (): IBagAttachmentData[] => [
  {
    id: '',
    type: '',
    fileName: '',
    signedUrl: '',
    action: '',
    attachmentTypeValuId: '',
  },
];
export const defaultBagCriteriaData = (): IBagCriteriaData => ({
  agent: '',
  minHawbValue: '',
  maxHawbValue: '',
  minHawbWeight: '',
  maxHawbWeight: '',
  clearanceType: '',
  airlineId: '',
  serviceTypeGroups: '',
  customer: '',
  objectGroup: '',
  finalFacility: '',
});
export const defaultBagMiscellaneousData = (): IBagMiscellaneousData => ({
  tareWeight: '',
});
export const defaultBagPieceData = (): IBagLinkedPieceData[] => [
  { id: '', action: '' },
];
export const defaultBagInformationData = (): IBagInformationData => ({
  bagId: 0,
  bagNumber: '',
  hawbId: 0,
  origin: '',
  destination: '',
  packagingType: 0,
  manifestNumber: '',
  flightNumber: '',
  description: '',
  notes: defaultBagNotesData(),
  attachmentList: defaultBagAttachmentsData(),
  criteria: defaultBagCriteriaData(),
  miscellaneous: defaultBagMiscellaneousData(),
  pieceList: defaultBagPieceData(),
  eventsList: [],
});
export const defaultGridDisplayColumns: string[] = [
  'select',
  'bagExt',
  'noofPieces',
  'weight',
  'manifestId',
  'packagingType',
  'action',
];

export const BagExcelHeaders: string[] = [
  'Bag',
  'Pieces',
  'Weight(lb)',
  'Manifest',
  'Packaging',
];

export const defautBagPagination = {
  fromIndex: 0,
  toIndex: 10,
  sortingOrder: searchDataParam.desc,
  sortingField: searchDataParam.bagExt,
};

export const desiredBagPackage = ['B', 'C', 'G', 'O', 'A'];
