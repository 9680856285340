import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SpinnerService } from './services/spinner.service';
import { MaterialModule } from './material.module';
import { SnakbarComponent } from './components/snakbar/snakbar.component';
import { SnakbarService } from './services/snakbar.service';
import { ModalService } from './services/modal.service';
import { LayoutModule } from './components/layout/layout.module';
import { AppBreadcrumbComponent } from './components/app-breadcrumb/app-breadcrumb.component';
import { AppDigitWithTwoDecimalNumberDirective } from './directives/digitsWithTwoDecimal.directive';
import { KeyStrokeDirectivesModule } from './directives/keystroke/keystrokes.module';
import { AddSymbolInputDirective } from './directives/addSymbol.directive';
import { AppAlphabetsWithNumbersDirective } from './directives/alphabetsWithNumbers.directive';
import { DateUtilService } from './utility/date-format';
import { AppNumbersWithHyphenDirective } from './directives/numbersWithHyphen.directive';
import { CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { SearchModule } from './components/search/search.module';
import { SearchOverlayService } from './services/search-overlay.service';
import { UppercaseDirective } from './directives/uppercase.directive';
@NgModule({
  declarations: [
    SnakbarComponent,
    AppDigitWithTwoDecimalNumberDirective,
    AddSymbolInputDirective,
    AppAlphabetsWithNumbersDirective,
    AppNumbersWithHyphenDirective,
    UppercaseDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AppBreadcrumbComponent,
    KeyStrokeDirectivesModule,
    DatePipe,
    CdkDropList,
    CdkDrag,
    SearchModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    LayoutModule,
    AppBreadcrumbComponent,
    AppDigitWithTwoDecimalNumberDirective,
    KeyStrokeDirectivesModule,
    AddSymbolInputDirective,
    AppAlphabetsWithNumbersDirective,
    AppNumbersWithHyphenDirective,
    CdkDropList,
    CdkDrag,
    SearchModule,
    UppercaseDirective
  ],
  providers: [
    SpinnerService,
    SnakbarService,
    ModalService,
    DateUtilService,
    DatePipe,
    SearchOverlayService,
  ],
})
export class SharedModule {}
