import { INotesSectionData } from 'src/app/shared/models/dynamic.model';

export interface Widget {
  id: number;
  title: string;
  value: string;
  extraClasses: string;
  iconName: string;
  name: string;
}

export interface Icon {
  label: string;
  icon: string;
  event: string;
}

export interface DataSourceItem {
  shipmentsCreation: string;
  time: string;
  dispCode: string;
  icon: string;
}

export interface HawbPieceFiedlsElement {
  id: string;
  pieceNumber: any;
  noofpieces: any;
  barcode: string;
  bag: string;
  weight: any;
  dimL: any;
  dimW: any;
  dimH: any;
  packagingType: string;
  action: string;
  pieceExt: string;
  dimLWH: string;
}

export interface HawbPieceElement {
  id: any;
  weight: any;
  pieceNumber: any;
  dimLWH: any;
  packagingType: string;
  isInputRow?: boolean;
  isEditable?: boolean;
  tempPieceId?: string | number;
  action?: string;
  attachments?: any;
  notes?: any;
  source?: any;
  hawbId?: any;
}
export interface HawbCommodityElement {
  hsCode: any;
  value: any;
  description: string;
  isInputRow?: boolean;
  id: number;
  hawbId: any;
}

export interface FieldOption {
  label: string;
  value: string;
}

export interface FormField {
  id: string;
  type: string;
  label: string;
  required?: boolean;
  pattern?: string;
  minValue?: number;
  maxValue?: number;
  options?: FieldOption[];
  emailPatternError?: string;
  requiredError?: string;
  allowNumeric?: boolean;
  noInitialWhiteSpace?: boolean;
  allowAlphabet?: boolean;
  allowAlphaNumeric?: boolean;
  allowAlphabetWithSpace?: boolean;
  defaultValue?: string;
}

export interface FormConfig {
  fields: FormField[];
}

export interface ComponentMapping {
  title: string;
  componentType: any;
}
export interface ComponentSharedMapping {
  title: string;
  componentType: any;
  importurl: string;
}

export interface TableColumns {
  key: string;
  label: string;
  value: string;
  isSelected: boolean;
  defaultView: boolean;
  isEditable?: boolean;
}

export interface hawbGridColumnData {
  hawb: number;
  shipper: string;
  consignee: string;
  pieces: number;
  value: string;
  weight: any;
  hawbId: number;
}

/* HAWB Summary Grid Result - Starts*/
export interface IHawbSummaryGridResult {
  statusCode: number;
  message: string;
  data: hawbSummaryGridData;
  code: any;
}

export interface IHawbInformationListResult {
  statusCode: number;
  message: string;
  data: hawbInformationListData;
}

export interface IOriginListResult {
  statusCode: number;
  message: string;
  data: Origin[];
}

export interface hawbSummaryGridData {
  totalRecords: number;
  sortingOrder: string;
  sortingField: string;
  hawb: hawbColumnData[];
}

export interface hawbInformationListData {
  totalRecords: number;
  sortingOrder: string;
  sortingField: string;
  hawb: hawbInformation[];
}

export interface hawbColumnData {
  hawbId: number;
  hawbExt: string;
  shipper: string;
  consignee: string;
  noofPieces: any;
  hawbValue: string;
  weight: any;
  hawb_ext?: string;
  checked?: boolean;
  tooltipInfo?: object;
}
/* HAWB Summary Grid Result - Ends*/

/* HAWB Summary Delete Result - Starts */
export interface IHawbDeleteResult {
  statusCode: number;
  message: string;
  data: any;
  code: number;
  statusCodes: number;
  status?: number;
  body?: any;
}
/* HAWB Summary Delete Result - Ends */

export interface hawbInformation {
  hawbId: number;
  hawbExt: string;
  origin: string;
  finalDestination: string;
  accountNumber: string;
  customerId: number;
  customerName: number;
  serviceType: number;
  tag: string;
  description: string;
  commodity: Commodity;
  timeline: Timeline[];
  roles: Roles[];
  pod: Pod[];
  statusCodeList: DispList[];
  routes: object;
  pieceList: PieceList[];
  commodityList: CommodityList[];
  attachmentList: Attachments[];
  miscellaneous: Miscellaneous;
  eventList: EventList[];
  hsCode: string;
  hawbValue: string;
  commodityDescription: string;
  totalNoOfPieces?: any;
  totalValue?: any;
  totalWeight?: any;
  hawbStatus: any;
  hawbCurrentStatus?: object;
  hawbPreviousStatus?: object;
  comments: string;
  originId?: string;
  destination?: string;
  notes: INotesSectionData;
  hawbStatusDate?: string;
}

export interface IHawbByIdResponse {
  statusCode: number;
  message: string;
  data: hawbInformation;
}

export interface Commodity {
  hsCode: string;
  hawbValue: string;
  description: string;
}
export interface Timeline {
  dateTime: string;
  details: string;
}

export interface Roles {
  id?: number;
  type?: string;
  name: string;
  email: string;
  phone: string;
  companyName: string;
  address: string;
  country: any;
  state: string;
  city: string;
  zipCode: string;
  action?: string;
  countryCode?: string;
  address_1: string;
}

export interface Pod {
  id: number;
  date: string;
  time: string;
  zone: string;
  agentName: string;
  comments: string;
  action: string;
  isInputRow?: boolean;
  info?: any;
}

export interface DispList {
  id?: number;
  date: string;
  time: string;
  zone?: string;
  codeDescription: string;
  note?: string;
  action?: string;
  isInputRow?: boolean;
  info?: any;
}

export interface PieceList {
  id: any;
  pieceNumber: any;
  noofpieces: any;
  hawb: string;
  barcode: string;
  bag: string;
  weight: any;
  dimLWH: any;
  packagingType: string;
  hawbId: string;
  hawbExt: string;
  action: string;
  attachments: any;
  dimL: any;
  dimW: any;
  dimH: any;
  isInputRow?: boolean;
  pieceExt: string;
  piece?: {};
  tempPieceId: number;
  notes: INotesSectionData;
  source?: string;
  packagingTypeName: string;
}

export interface CommodityList {
  id: number;
  hsCode: string;
  countryOfManufacturer?: string;
  description: string;
  value: any;
  hawbId: number;
  url: string;
  attachments?: [];
  miscellaneous?: CommodityMiscellaneous;
  isInputRow?: boolean;
  action?: string;
  commodity?: {};
  tempCommodityId?: number;
  notes?: INotesSectionData;
}
export interface Attachments {
  id?: string;
  type: string;
  fileName: string;
  signedUrl?: string;
  action?: string;
  isInputRow?: boolean;
  isFromScreen?: string;
  attachmentId?: string | null;
  isSavedData?: boolean;
}

export interface CommodityMiscellaneous {
  listPrice: string;
  orderDate: string;
  orderId: string;
  retailPrice: string;
  url: string;
  action: string;
  id: string;
  isSavedData?: boolean;
}

export interface Miscellaneous {
  isResidential: boolean;
  signatureRequired: boolean;
}
export interface EventList {
  id: string;
  date: string;
  time: string;
  zone: string;
  name: string;
  info: any;
}

export interface HawbInfoScreenStateModel {
  data: hawbInformation;
}

export const defaultMiscellaneous: Miscellaneous = {
  isResidential: false,
  signatureRequired: false,
};

export const defaultNoteSection: INotesSectionData = {
  ibc: [],
  customer: [],
};

export const defaultCommodity: Commodity = {
  hsCode: '',
  hawbValue: '',
  description: '',
};

export const defaultHawbInformation: hawbInformation = {
  hawbId: 0,
  hawbExt: '',
  origin: '',
  finalDestination: '',
  accountNumber: '',
  customerId: 0,
  customerName: 0,
  serviceType: 0,
  tag: '',
  description: '',
  commodity: defaultCommodity,
  timeline: [],
  roles: [],
  notes: defaultNoteSection,
  pod: [],
  statusCodeList: [],
  routes: {},
  pieceList: [],
  commodityList: [],
  attachmentList: [],
  miscellaneous: defaultMiscellaneous,
  eventList: [],
  hsCode: '',
  hawbValue: '',
  commodityDescription: '',
  hawbStatus: '',
  comments: '',
  hawbPreviousStatus: {},
};

export interface CustomerInfo {
  id: number;
  name: string;
  number: string;
}

export const defaultCommodityMiscellaneous: CommodityMiscellaneous = {
  listPrice: '',
  orderDate: '',
  orderId: '',
  retailPrice: '',
  url: '',
  action: 'add',
  id: '',
  isSavedData: false,
};

export const defaultAttachments: Attachments = {
  type: '',
  fileName: '',
};

export interface TransformedPiece {
  piece: HawbPieceFiedlsElement;
  notes: INotesSectionData;
  attachments: Attachments;
}

export interface TransformedHawbData {
  commodity: {
    hsCode: string;
    hawbValue: string;
    description: string;
  };
}

export interface TransformedCommodity {
  commodity: CommodityList;
  notes: INotesSectionData;
  attachments: Attachments;
  miscellaneous: CommodityMiscellaneous;
}

export interface HawbSearchPayload {
  hawbId?: string;
  hawb_ext?: string;
  originId?: number;
  destinationId?: number;
  customerId?: number;
  customerName?: string;
  accountNumber?: number;
  serviceType?: string;
  numPieces?: 0;
  hawbValue?: 0;
  totalWeight?: 0;
  searchType: string;
  data?: any;
  customer?: any;
  minHawbValue?: string;
  maxHawbValue?: string;
  minHawbWeight?: string;
  maxHawbWeight?: string;
  paging: {
    fromIndex: 0;
    toIndex: 0;
    sortingOrder: string;
    sortingField: string;
  };
}

export interface HawbInformationSearchPayload {
  hawbId?: number;
  hawbExt?: any;
  originId?: any;
  destinationId?: any;
  accountNumber?: any;
  customerId?: any;
  customerName?: any;
  serviceType?: any;
  numPieces?: any;
  hawbValue?: any;
  totalWeight?: any;
  searchType?: any;
  minHawbValue?: string;
  maxHawbValue?: string;
  minHawbWeight?: string;
  maxHawbWeight?: string;
  paging: {
    fromIndex: number;
    toIndex: number;
    sortingOrder?: string;
    sortingField?: string;
  };
}

export interface Origin {
  id: number;
  name: string;
}

export interface IHawbPieceInformationSuccessResponse {
  statusCode: number;
  message: string;
  data: PieceList;
  status?: number;
}

export interface TransformedPayload {
  ids: {
    pieceIds: number[];
    hawbId: number;
  }[];
}
