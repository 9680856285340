import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { SnakbarService } from './snakbar.service';
import {
  dateTimeFormat,
  downloadInfoPageSectionHeaders,
  downloadInfoPageSectionKeys,
  fileFormats,
  fileFormatsType,
  ModalPopupMessages,
} from '../utility/constants';
import { GridEventColumns } from '../components/events/event.config';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  constructor(
    private datePipe: DatePipe,
    private snakbarService: SnakbarService
  ) {}

  public generateExcelCSVFile(excelData: any): void {
    const workbook = new ExcelJS.Workbook(); // create workbook
    const worksheet = workbook.addWorksheet(excelData.name); // adding sheet to workbook
    this.loadHeadersAndDataBasedOnPage(excelData, worksheet);
    this.autoWidth(worksheet); // set width for cells
    worksheet.eachRow(function (Row) {
      Row.alignment = { horizontal: 'left' }; // left align cells
    });
    this.saveFile(
      excelData.selectedFileType === fileFormats.csv
        ? workbook.csv
        : workbook.xlsx,
      excelData
    ); // save file & download
    this.snakbarService.openCustomisedSnackBar(
      ModalPopupMessages.downloadSuccess(excelData.successMessage),
      ModalPopupMessages.success
    );
  }

  private loadHeadersAndDataBasedOnPage(
    excelData: any,
    worksheet: ExcelJS.Worksheet
  ) {
    if (excelData.isFromSummary) {
      worksheet.addRow(excelData.headers); // adding headers
      this.setDataForHeaders(excelData, worksheet, 'tableData'); // adding row data's
    } else {
      excelData.sectionNames.forEach((headerName: string) => {
        const sectionHeaderRow = worksheet.addRow([headerName]); // adding section headers
        sectionHeaderRow.font = { bold: true };
        const sectionColumnHeader = worksheet.addRow(
          this.getHeaders(excelData, headerName)
        ); // adding headers
        sectionColumnHeader.font = { bold: true };
        this.getBorders(sectionColumnHeader, worksheet);
        this.setDataForHeaders(
          excelData.pageData[this.getHeaderSectionKeys(headerName)],
          worksheet,
          'data'
        ); // adding row data's
        worksheet.addRow([]); // adding empty row
      });
    }
  }

  private getHeaders(excelData: any, headerName: string) {
    if (
      headerName === downloadInfoPageSectionHeaders.mawbInfo ||
      headerName === downloadInfoPageSectionHeaders.mawbmiscellaneous ||
      headerName === downloadInfoPageSectionHeaders.details ||
      headerName === downloadInfoPageSectionHeaders.mawbManifests ||
      headerName === downloadInfoPageSectionHeaders.mawbFlights
    ) {
      return excelData.pageData[this.getHeaderSectionKeys(headerName)].headers;
    } else if (headerName === downloadInfoPageSectionHeaders.events) {
      return GridEventColumns.map(eventItem => eventItem.label);
    } else if (headerName === downloadInfoPageSectionHeaders.notes) {
      return ['Note', 'NotesType', 'Created By', 'Date and Time'];
    } else if (headerName === downloadInfoPageSectionHeaders.attachments) {
      return [
        'Type',
        'File Name',
        'Description',
        'Created By',
        'Date and Time',
      ];
    }
  }

  private getHeaderSectionKeys(sectionName: string) {
    if (sectionName === 'MAWB Information') {
      return downloadInfoPageSectionKeys.mawbInfo;
    } else if (sectionName === 'Miscellaneous') {
      return downloadInfoPageSectionKeys.mawbmiscellaneous;
    } else if (sectionName === 'Details') {
      return downloadInfoPageSectionKeys.details;
    } else if (sectionName === 'Events') {
      return downloadInfoPageSectionKeys.events;
    } else if (sectionName === 'Attachments') {
      return downloadInfoPageSectionKeys.attachments;
    } else if (sectionName === 'Notes') {
      return downloadInfoPageSectionKeys.notes;
    } else if (sectionName === 'Manifest(s) linked') {
      return downloadInfoPageSectionKeys.mawbManifests;
    } else if (sectionName === 'Flight(s) linked') {
      return downloadInfoPageSectionKeys.mawbFlights;
    } else {
      return '';
    }
  }

  private getBorders(dataToIterate: ExcelJS.Row, worksheet: ExcelJS.Worksheet) {
    dataToIterate.eachCell({ includeEmpty: true }, (cell_v, colNumber) => {
      if (colNumber <= worksheet.columnCount) {
        cell_v.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    });
  }

  private setDataForHeaders(
    excelData: any,
    worksheet: ExcelJS.Worksheet,
    param: any
  ) {
    excelData[param].forEach((item: any) => {
      const row: any[] = [];
      excelData.headerKeys.forEach((header: string) => {
        row.push(item[header]);
      });
      const rowData = worksheet.addRow(row);
      this.getBorders(rowData, worksheet);
    });
  }

  private autoWidth = (worksheet: any, minimalWidth = 10) => {
    worksheet.columns.forEach((column: any) => {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, (cell: any) => {
        maxColumnLength = Math.max(
          maxColumnLength,
          minimalWidth,
          cell.value ? cell.value.toString().length : 0
        );
      });
      column.width = maxColumnLength + 2;
    });
  };

  private saveFile(workbookType: ExcelJS.Csv | ExcelJS.Xlsx, excelData: any) {
    workbookType.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], {
        type:
          excelData.selectedFileType === fileFormats.csv
            ? fileFormatsType.csv
            : fileFormatsType.xlsx,
      });
      FileSaver(blob, this.getFileName(excelData));
    });
  }

  private getFileName(excelData: any) {
    const extension =
      excelData.selectedFileType === fileFormats.csv
        ? fileFormats.csv
        : fileFormats.xlsx;
    const dateTime = this.datePipe.transform(
      new Date(),
      dateTimeFormat.dateTime24hours
    );
    if (excelData.isFromSummary) {
      return `${excelData.name}_${dateTime}_John.${extension}`;
    } else {
      return `${excelData.name}_${excelData.extOrNumber}_${dateTime}_John.${extension}`;
    }
  }
}
