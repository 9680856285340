import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import {
  HawbInfoScreenStateModel,
  defaultHawbInformation,
  Roles,
  hawbInformation,
  Miscellaneous,
  PieceList,
  CommodityList,
  CommodityMiscellaneous,
  EventList,
  hawbColumnData,
  Origin,
} from 'src/app/features/model/hawb.model';
import {
  AddCommodity,
  AddDispCode,
  AddEvents,
  AddMiscellaneousCommodity,
  AddPiece,
  AddPodCode,
  DeleteCommodity,
  DeletePiece,
  RemoveUnsavedMiscellaneousCommodities,
  ResetCommodity,
  ResetDispCodes,
  ResetEvents,
  ResetMiscellaneousCommodity,
  ResetPODCodes,
  ResetPiece,
  UpdateCommodity,
  UpdateHawbInformation,
  UpdateMiscellaneous,
  UpdatePiece,
  FetchHawbsList,
  ResetHawbsList,
  FetchSelectedHawbById,
  FetchHawbsInformationByHawbExt,
  ResetHawbInfo,
  DeletePodCode,
  DeleteStatusCode,
  FetchHawbsInformationListSuccess,
} from './hawb.action';
import { CommonService } from 'src/app/shared/services/common.service';

// Roles
export class UpdateConsignee {
  static readonly type = '[Roles] Update Consignee';
  constructor(public payload: any) {}
}
export class UpdateShipper {
  static readonly type = '[Roles] Update Shipper';
  constructor(public payload: any) {}
}

@State<HawbInfoScreenStateModel>({
  name: 'roles',
  defaults: {
    data: defaultHawbInformation,
  },
})
@Injectable()
export class RolesState {
  @Action(UpdateConsignee)
  updateConsignee(
    ctx: StateContext<HawbInfoScreenStateModel>,
    action: UpdateConsignee
  ) {
    const state = ctx.getState();
    const updatedData = {
      ...state.data,
      roles: updateRoles(state.data.roles, action.payload, 'C'),
    };
    ctx.setState({
      ...state,
      data: updatedData,
    });
  }

  @Action(UpdateShipper)
  updateShipper(
    ctx: StateContext<HawbInfoScreenStateModel>,
    action: UpdateShipper
  ) {
    const state = ctx.getState();
    const updatedData = {
      ...state.data,
      roles: updateRoles(state.data.roles, action.payload, 'S'),
    };
    ctx.setState({
      ...state,
      data: updatedData,
    });
  }
}

function updateRoles(roles: Roles[], payload: any, type: string): Roles[] {
  const existingRoleIndex = roles.findIndex(role => role.type === type);
  if (existingRoleIndex !== -1) {
    return roles.map((role, index) =>
      index === existingRoleIndex ? payload : role
    );
  } else {
    return [...roles, payload];
  }
}

// Disp Codes
export class DispCodesStateModel {
  hawbInformation!: hawbInformation;
}

@State<DispCodesStateModel>({
  name: 'dispCodes',
  defaults: {
    hawbInformation: defaultHawbInformation,
  },
})
@Injectable()
export class DispCodesState {
  @Action(AddDispCode)
  addDispCode(
    ctx: StateContext<DispCodesStateModel>,
    { payload }: AddDispCode
  ) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        statusCodeList: [...state.hawbInformation.statusCodeList, payload],
      },
    };
    ctx.setState(newState);
  }

  @Action(DeleteStatusCode)
  deleteDispCode(ctx: StateContext<HawbStateModel>, { id }: DeleteStatusCode) {
    const state = ctx.getState();
    const updatedStatus = state.hawbInformation.statusCodeList.filter(
      code => code.id !== id
    );
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        statusCodeList: updatedStatus,
      },
    };
    ctx.setState(newState);
  }

  @Action(ResetDispCodes)
  resetDispCodes(ctx: StateContext<DispCodesStateModel>) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        statusCodeList: [],
      },
    };
    ctx.setState(newState);
  }
}

// POD
export class HawbStateModel {
  hawbInformation!: hawbInformation;
}

@State<HawbStateModel>({
  name: 'podCodes',
  defaults: {
    hawbInformation: defaultHawbInformation,
  },
})
@Injectable()
export class PODState {
  @Action(AddPodCode)
  addPodCode(ctx: StateContext<HawbStateModel>, { payload }: AddPodCode) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        pod: [...state.hawbInformation.pod, payload],
      },
    };
    ctx.setState(newState);
  }

  @Action(DeletePodCode)
  deletePodCode(ctx: StateContext<HawbStateModel>, { id }: DeletePodCode) {
    const state = ctx.getState();
    const updatedPods = state.hawbInformation.pod.filter(pod => pod.id !== id);
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        pod: updatedPods,
      },
    };
    ctx.setState(newState);
  }

  @Action(ResetPODCodes)
  resetpodCodes(ctx: StateContext<HawbStateModel>) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        pod: [],
      },
    };
    ctx.setState(newState);
  }
}

// Miscellaneous Section
export interface MiscellaneousStateModel {
  miscellaneous: Miscellaneous;
}

@State<MiscellaneousStateModel>({
  name: 'miscellaneous',
  defaults: {
    miscellaneous: {
      isResidential: false,
      signatureRequired: false,
    },
  },
})
@Injectable()
export class MiscellaneousState {
  @Action(UpdateMiscellaneous)
  updateMiscellaneous(
    ctx: StateContext<MiscellaneousStateModel>,
    action: UpdateMiscellaneous
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      miscellaneous: action.payload,
    });
  }
}

// Hawb Crud
export interface HawbInformationStateModel {
  hawbInformation: hawbInformation;
  hawbsInformationList: hawbInformation[];
}
@State<HawbInformationStateModel>({
  name: 'hawbInformation',
  defaults: {
    hawbInformation: defaultHawbInformation,
    hawbsInformationList: [],
  },
})
@Injectable()
export class HawbInformationState {
  @Action(UpdateHawbInformation)
  updateHawbInformation(
    ctx: StateContext<HawbInformationStateModel>,
    action: UpdateHawbInformation
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbInformation: action.payload,
    });
  }

  @Action(FetchHawbsInformationListSuccess)
  fetchHawbsInformationListSuccess(
    { getState, setState }: StateContext<HawbInformationStateModel>,
    { hawbs }: FetchHawbsInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      hawbsInformationList: hawbs,
    });
  }

  @Action(FetchSelectedHawbById)
  FetchSelectedHawbById(
    ctx: StateContext<HawbInformationStateModel>,
    action: FetchSelectedHawbById
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbInformation: state.hawbsInformationList.filter(
        element => element.hawbId == action.hawbId
      )[0],
    });
  }

  @Action(FetchHawbsInformationByHawbExt)
  FetchHawbsInformationListById(
    ctx: StateContext<HawbInformationStateModel>,
    action: any
  ) {
    const state = ctx.getState();
    const data = action.payload;
    ctx.setState({
      ...state,
      hawbInformation: data.hawbInformation,
      hawbsInformationList: data.hawbsInformationList,
    });
  }

  @Action(ResetHawbInfo)
  resetHawbInfo(
    ctx: StateContext<HawbInformationStateModel>,
    action: ResetHawbInfo
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbInformation: defaultHawbInformation,
      hawbsInformationList: [],
    });
  }
}

// Piece table data
export interface PieceStateModel {
  pieces: PieceList[];
}

@State<PieceStateModel>({
  name: 'piece',
  defaults: {
    pieces: [],
  },
})
@Injectable()
export class PieceState {
  @Action(AddPiece)
  addPiece(ctx: StateContext<PieceStateModel>, { payload }: AddPiece) {
    const state = ctx.getState();
    if (payload && typeof payload === 'object' && !Array.isArray(payload)) {
      ctx.setState({
        ...state,
        pieces: [...state.pieces, payload],
      });
    }
  }

  @Action(UpdatePiece)
  updatePiece(ctx: StateContext<PieceStateModel>, { payload }: UpdatePiece) {
    const state = ctx.getState();
    const updatedPieces = state.pieces.map(
      piece => (piece.id == payload.id ? { ...piece, ...payload } : piece)
      // (piece.id == 0 ? piece.tempPieceId : piece.id) == payload.id
      //   ? { ...piece, ...payload }
      //   : piece
    );
    ctx.setState({
      ...state,
      pieces: updatedPieces,
    });
  }

  @Action(DeletePiece)
  deletePiece(ctx: StateContext<PieceStateModel>, { payload }: DeletePiece) {
    const state = ctx.getState();
    const filteredPieces = state.pieces.filter(piece => piece.id !== payload);
    ctx.setState({
      ...state,
      pieces: filteredPieces,
    });
  }

  @Action(ResetPiece)
  resetPiece({ setState }: StateContext<PieceStateModel>) {
    setState({
      pieces: [],
    });
  }
}

// Commodity Table
export interface CommodityStateModel {
  commodities: CommodityList[];
}

@State<CommodityStateModel>({
  name: 'commodities',
  defaults: {
    commodities: [],
  },
})
@Injectable()
export class CommodityState {
  @Action(AddCommodity)
  addCommodity(
    { getState, patchState }: StateContext<CommodityStateModel>,
    { payload }: AddCommodity
  ) {
    const state = getState();
    const existingIndex = state.commodities.findIndex(item =>
      payload.id == 0
        ? item.tempCommodityId === payload.tempCommodityId
        : item.id == payload.id
    );
    if (existingIndex !== -1) {
      const updatedCommodities = [...state.commodities];
      updatedCommodities[existingIndex] = payload;
      patchState({
        commodities: updatedCommodities,
      });
    } else {
      patchState({
        commodities: [...state.commodities, payload],
      });
    }
  }

  @Action(UpdateCommodity)
  updateCommodity(
    ctx: StateContext<CommodityStateModel>,
    { payload }: UpdateCommodity
  ) {
    const state = ctx.getState();
    const updatedCommodities = state.commodities.map(commodity =>
      commodity.id === payload.id ? { ...commodity, ...payload } : commodity
    );
    ctx.setState({
      ...state,
      commodities: updatedCommodities,
    });
  }

  @Action(ResetCommodity)
  resetCommodity({ setState }: StateContext<CommodityStateModel>) {
    setState({
      commodities: [],
    });
  }

  @Action(DeleteCommodity)
  deleteCommodity(
    ctx: StateContext<CommodityStateModel>,
    { payload }: DeleteCommodity
  ) {
    const state = ctx.getState();
    const filteredcommodity = state.commodities.filter(
      commodities => commodities.id !== payload
    );
    ctx.setState({
      ...state,
      commodities: filteredcommodity,
    });
  }
}

// Miscellaneous Commodity
export class MiscellaneousCommodityStateModel {
  miscellaneousCommodities!: CommodityMiscellaneous[];
}

@State<MiscellaneousCommodityStateModel>({
  name: 'miscellaneousCommodities',
  defaults: {
    miscellaneousCommodities: [],
  },
})
@Injectable()
export class MiscellaneousCommodityState {
  @Action(AddMiscellaneousCommodity)
  updateForm(
    ctx: StateContext<MiscellaneousCommodityStateModel>,
    { payload }: AddMiscellaneousCommodity
  ) {
    const state = ctx.getState();
    const updatedMiscellaneousCommodities = [...state.miscellaneousCommodities];
    const existingIndex = updatedMiscellaneousCommodities.findIndex(
      misc => misc.id === payload.id
    );
    if (existingIndex !== -1) {
      updatedMiscellaneousCommodities[existingIndex] = payload;
    } else {
      updatedMiscellaneousCommodities.push(payload);
    }
    ctx.setState({
      ...state,
      miscellaneousCommodities: updatedMiscellaneousCommodities,
    });
  }

  @Action(RemoveUnsavedMiscellaneousCommodities)
  removeUnsavedMiscellaneousCommodities(
    ctx: StateContext<MiscellaneousCommodityStateModel>
  ) {
    const state = ctx.getState();
    const updatedMiscellaneousCommodities =
      state.miscellaneousCommodities.filter(misc => misc.isSavedData);
    ctx.setState({
      ...state,
      miscellaneousCommodities: updatedMiscellaneousCommodities,
    });
  }

  @Action(ResetMiscellaneousCommodity)
  resetMiscellaneousCommodity(
    ctx: StateContext<MiscellaneousCommodityStateModel>
  ) {
    ctx.setState({
      miscellaneousCommodities: [],
    });
  }
}
// Events
export interface EventsStateModel {
  events: EventList[];
}
@Injectable()
@State<EventsStateModel>({
  name: 'events',
  defaults: {
    events: [],
  },
})
export class EventsState {
  @Action(AddEvents)
  addEvents(ctx: StateContext<EventsStateModel>, action: AddEvents) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      events: action.payload,
    });
  }

  @Action(ResetEvents)
  resetEvents(ctx: StateContext<EventsStateModel>, action: ResetEvents) {
    ctx.setState({
      events: [],
    });
  }
}

// Hawbs
export interface HawbsListStateModel {
  hawbs: hawbColumnData[];
  totalData: number;
  origins: Origin[];
  destinations: Origin[];
}

@Injectable()
@State<HawbsListStateModel>({
  name: 'hawbs',
  defaults: {
    hawbs: [],
    totalData: 0,
    origins: [],
    destinations: [],
  },
})
export class HawbsListState {
  constructor(private commonService: CommonService) {}

  @Action(FetchHawbsList)
  FetchHawbsList(
    ctx: StateContext<HawbsListStateModel>,
    action: FetchHawbsList
  ) {
    const state = ctx.getState();
    const payload = action.payload;
    if (payload.data && payload.data.hawb && payload.data.totalRecords) {
      ctx.setState({
        ...state,
        hawbs: payload.data.hawb,
        totalData: payload.data.totalRecords,
      });
    } else {
      ctx.setState({
        ...state,
        hawbs: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetHawbsList)
  ResetHawbsList(
    ctx: StateContext<HawbsListStateModel>,
    action: ResetHawbsList
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbs: [],
      totalData: 0,
    });
  }
}
