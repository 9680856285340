<div class="notification-snackbar-container">
  <app-svg-icon
    class="snackbar-icon"
    [iconName]="iconClassName"
    [isStaticSvg]="true"
    [isFrom]="'Snackbar'">
  </app-svg-icon>
  <div class="ibc-heading-h7-subtitle">{{ data.message }}</div>
  <app-svg-icon
    class="cursor dismiss-icon"
    [iconName]="'close'"
    [isStaticSvg]="true"
    [isFrom]="'Snackbar'"
    (staticIconClicked)="dismiss()"></app-svg-icon>
</div>
