import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ModalPopupMessages } from '../../utility/constants';

@Component({
  selector: 'app-notification-snackbar',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './notification-snackbar.component.html',
  styleUrl: './notification-snackbar.component.scss',
})
export class NotificationSnackbarComponent implements OnInit {
  public iconClassName!: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>
  ) {}

  ngOnInit(): void {
    this.iconClassName =
      this.data.type === ModalPopupMessages.success
        ? 'successSnackbar'
        : this.data.type === 'error'
          ? 'errorSnackbar'
          : 'warningSnackbar';
  }

  public dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
