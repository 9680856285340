import { Component } from '@angular/core';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [BreadcrumbModule],
  providers: [BreadcrumbService],
  templateUrl: './app-breadcrumb.component.html',
  styleUrl: './app-breadcrumb.component.scss',
})
export class AppBreadcrumbComponent {
  constructor(private breadcrumbService: BreadcrumbService) {}
  ngOnInit(): void {
    this.breadcrumbService.set('@dashboard', 'Dashboard');
    this.breadcrumbService.set('@hawb', 'Home');
    this.breadcrumbService.set('@bag', 'Home');
    this.breadcrumbService.set('@manifest', 'Home');
    this.breadcrumbService.set('@mawb', 'Home');
    this.breadcrumbService.set('@bag-information', 'Bags');
    this.breadcrumbService.set('@hawb-information', 'Shipment');
    this.breadcrumbService.set('@manifest-information', 'Manifest');
    this.breadcrumbService.set('@piece-information', 'Piece');
    this.breadcrumbService.set('@commodity-information', 'Commodity');
    this.breadcrumbService.set('@mawb-information', 'MAWB');
    this.breadcrumbService.set('@sm-dashboard', 'Home');
    this.breadcrumbService.set('@flight-information', 'Flight');
    this.breadcrumbService.set('@agent-summary', 'Agent');
    this.breadcrumbService.set('@agent-information', 'Agent Information');
  }
}
