import { FormConfig } from '../../models/dynamic.model';

/* MAWB - Customize Columns */
export const MawbColumnsList: string[] = [
  'select',
  'mawb',
  'origin',
  'destination',
  'agentCode',
  'airlineCode',
  'clearanceType',
  'agentName',
  'airlineName',
  'action',
];

/* MAWB - Manifest Customize Columns */
export const MAWBManifestCustomizeColumns: string[] = [
  'select',
  'manifestNumber',
  'date',
  'bags',
  'shipments',
  'pieces',
  'action',
];

/* MAWB - Manifest Customize Columns */
export const MAWBFlightCustomizeColumns: string[] = [
  'select',
  'airlineCode',
  'flightNumber',
  'slacCount',
  'slacWeight',
  'arrivalDate',
  'mawbPart',
  'action',
];

export const AgentColumnList: string[] = [
  'select',
  'agentCode',
  'agentName',
  'agentType',
  'facilities',
  'agentStatus',
  'action',
];

export const AgentFacilityColumnList: string[] = [
  'select',
  'facilityCode',
  'action',
];

export const selectTableMawbManifestFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'searchInput',
      type: 'input',
      label: '',
      minValue: 1,
      maxValue: 25,
      allowNumeric: true,
    },
  ],
};
