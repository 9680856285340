import { Injectable, Injector, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SnakbarComponent } from '../components/snakbar/snakbar.component';
import { NotificationSnackbarComponent } from '../components/notification-snackbar/notification-snackbar.component';
import { ModalPopupMessages } from '../utility/constants';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class SnakbarService implements OnDestroy {
  private snackbarDuration = 10000;
  timerSubscription: Subscription = new Subscription();

  constructor(
    private snackBar: MatSnackBar,
    private injector: Injector
  ) {}

  ngOnDestroy(): void {
    this.clearTimer();
  }

  public openSnackBar(message: any): void {
    this.clearTimer();
    this.setOverlayValue(true);
    const config: MatSnackBarConfig = {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'snackbar',
      duration: this.snackbarDuration,
    };

    const snackBarRef = this.snackBar.openFromComponent(SnakbarComponent, {
      ...config,
      data: {
        message,
        closeIcon: 'custom_close',
      },
    });

    this.timerSubscription = snackBarRef.afterDismissed().subscribe(() => {
      this.clearTimer();
      this.setOverlayValue(false);
    });
  }

  public openCustomisedSnackBar(message: string, type: string): void {
    this.clearTimer();
    this.setOverlayValue(true);
    const config: MatSnackBarConfig = {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass:
        type === ModalPopupMessages.success
          ? 'success-snackbar'
          : type === 'error'
            ? 'error-snackbar'
            : 'warning-snackbar',
      duration: this.snackbarDuration,
    };

    const snackBarRef = this.snackBar.openFromComponent(
      NotificationSnackbarComponent,
      {
        ...config,
        data: {
          message,
          type,
        },
      }
    );

    this.timerSubscription = snackBarRef.afterDismissed().subscribe(() => {
      this.clearTimer();
      this.setOverlayValue(false);
    });
  }

  private clearTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  private setOverlayValue(isOpen: boolean) {
    const commonService = this.injector.get(CommonService);
    commonService.setOverlayOpen(isOpen);
  }
}
