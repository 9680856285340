import { environment } from '../../../environments/environment';

export const URLConstants = {
  deleteHawb: environment.API_BASE_URL + '/v1/hawb/delete',
  getAccountDetails: environment.API_BASE_URL + '/v1/master/account/fetch/',
  getCustomerDetails: environment.API_BASE_URL + '/v1/master/customer/fetch/',
  getCountryDetails: environment.API_BASE_URL + '/v1/master/lookup',
  getStateDetails: environment.API_BASE_URL + '/v1/master/locality/search/',
  saveHawbInformation: environment.API_BASE_URL + '/v1/hawb/create',
  updateHawbInformation: environment.API_BASE_URL + '/v1/hawb/update',
  getHawbDataById: environment.API_BASE_URL + '/v1/hawb/fetch/',
  getDeleteCommodityDataById:
    environment.API_BASE_URL + '/v1/hawb/commodities/delete',
  getSaveCommodityDataById:
    environment.API_BASE_URL + '/v1/hawb/commodities/add',
  getUpdateCommodityDataById:
    environment.API_BASE_URL + '/v1/hawb/commodities/update',
  getSavePieceDataById: environment.API_BASE_URL + '/v1/hawb/piece/create',
  getUpdatePieceDataById: environment.API_BASE_URL + '/v1/hawb/piece/update',
  getDeletePieceDataById: environment.API_BASE_URL + '/v1/hawb/piece/delete',

  //Hawb Summary //
  getHawbList: environment.API_BASE_URL + '/v1/hawb/search',
  getHawbListMock: environment.MOCK_API_URL + '/assets/mock/hawbList.json',
  masterLookup: environment.API_BASE_URL + '/v1/master/lookup/',

  //Hawb Information //
  getHawbInfoList: environment.API_BASE_URL + '/v1/hawb/search',
  getHawbInfoListMock: environment.MOCK_API_URL + '/assets/mock/hawbInfo.json',
  getHawbById: environment.API_BASE_URL + '/v1/hawb/fetch',
  getPieceHawbData: environment.API_BASE_URL + '/v1/hawb/validate/piece',

  // Bag Summary //
  getBagList: environment.API_BASE_URL + '/v1/bag/search',

  // Bag Information //
  checkBagExt: environment.API_BASE_URL + '/v1/bag/fetch/',
  getPieceData: environment.API_BASE_URL + '/v1/bag/getPiece',
  saveBagData: environment.API_BASE_URL + '/v1/bag/create',
  updateBagData: environment.API_BASE_URL + '/v1/bag/update',
  deleteBag: environment.API_BASE_URL + '/v1/bag/delete',
  deleteBagPieces: environment.API_BASE_URL + '/v1/bag/pieces/delete',

  // Common Lookup's //
  requestType: environment.API_BASE_URL + '/v1/master/lookup/',
  originList: environment.MOCK_API_URL + '/assets/mock/originOptions.json',
  destinationList:
    environment.MOCK_API_URL + '/assets/mock/destinationOptions.json',

  // Manifest Summary
  getManifestList: environment.API_BASE_URL + '/v1/manifest/search',
  deleteManifest: environment.API_BASE_URL + '/v1/manifest/delete',
  getManifestById: environment.API_BASE_URL + '/v1/manifest/fetch/',

  // Manifest Information
  getBagsForManifest: environment.API_BASE_URL + '/v1/manifest/getBag',
  createManifest: environment.API_BASE_URL + '/v1/manifest/create',
  updateManifest: environment.API_BASE_URL + '/v1/manifest/update',
  deleteManifestBags: environment.API_BASE_URL + '/v1/manifest/bags/delete',

  // Attachment
  signedurl: environment.API_BASE_URL + '/v1/attachment/getSignedUrl',
  downloadAttachmnet: environment.API_BASE_URL + '/v1/attachment/download',

  // Mawb
  checkMawbExists: environment.API_BASE_URL + '/v1/mawb/validate',

  // Flight
  checkFlightExists: environment.API_BASE_URL + '/v1/flight/validate',

  // Mawb Information //
  checkMawbExt: environment.API_BASE_URL + '/v1/mawb/fetch/',
  getManifestData: environment.API_BASE_URL + '/v1/manifest/getManifest/manIds',
  saveMawbData: environment.API_BASE_URL + '/v1/mawb/create',
  updateMawbData: environment.API_BASE_URL + '/v1/mawb/update',
  deleteMawbManifests: environment.API_BASE_URL + '/v1/mawb/deleteManifest',
  addMawbManifests: environment.API_BASE_URL + '/v1/mawb/addManifest',
  deleteFlight: environment.API_BASE_URL + '/v1/mawb/flight/delete',
  getSaveFlightDataById: environment.API_BASE_URL + '/v1/mawb/flight/create',
  getUpdateFlightDataById: environment.API_BASE_URL + '/v1/mawb/flight/update',

  // Mawb Summary
  getMawbList: environment.API_BASE_URL + '/v1/mawb/basicSearch',
  deleteMawb: environment.API_BASE_URL + '/v1/mawb/delete',
  publishMawb: environment.API_BASE_URL + '/v1/mawb/publish',

  // Basic Search
  saveUpdatePinSearch: environment.API_BASE_URL + '/v1/search/pinOrUnpin',
  getRecentAndPinnedSearchesList:
    environment.API_BASE_URL + '/v1/search/getRecent',
  getSavedSearchesList: environment.API_BASE_URL + '/v1/search/getSaved',
  saveUpdateNewSearch: environment.API_BASE_URL + '/v1/search/updateSaved',
  deleteSearch: environment.API_BASE_URL + '/v1/search/deleteSaved',
};
