export const ValidatorPattern = {
  Email_Validator: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}',
  Phone_Validator_With10_Numbers: 'd{10}',
  OnlyAlpha_Validator: '^[a-zA-Z]+(?: [a-zA-Z]+)*$',
  SevenDigitTwoDecimal: /^\d{0,7}\.?\d{0,2}$/g,
  SeventeenDigitTwoDecimal: /^\d{0,17}\.?\d{0,2}$/g,
  FourAlphabetsWithNumbers: `^[A-Za-z]{0,4}[0-9]{0,6}$`,
  TwoAlphabetsWithNumbers: `^[A-Za-z]{0,2}[0-9]{0,4}$`,
  DateMMDDYYYYFormat: 'MM/dd/yyyy HH:mm:ss zzz',
  IST: 'IST',
  ThreeNumbersHyphenEightNumbers: /(?<=^\d{3})(?=\d)/g,
  ThreeAlphabetsWithNumbers: '^(?:[A-Za-z]{1,3}|[A-Za-z]{3}[0-9]{1,5})$',
};
