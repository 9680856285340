<div class="message-popup-wrapper">
  <div class="message-popup-header">
    <div class="ibc-body-text-large-bold">{{ messagePopupData.title }}</div>
    <div class="card-action-icons">
      <app-svg-icon
        [iconName]="'close'"
        class="cursor"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (staticIconClicked)="closePopup()"></app-svg-icon>
    </div>
  </div>

  <div
    class="message-popup-content {{
      messagePopupData.code?.isForDownload ? 'pb-20' : ''
    }}">
    <div
      class="ibc-body-text-medium confirmation-message"
      *ngIf="messagePopupData.code?.message">
      {{ messagePopupData.code?.message }}
    </div>
    <ng-container *ngIf="messagePopupData.code?.isMultiple">
      <div>
        <div
          class="ibc-body-text-medium confirmation-text"
          *ngIf="messagePopupData.code?.message !== ''">
          {{ deleteText }}
        </div>
        <mat-form-field
          appearance="outline"
          class="mat-md w-full input-wrapper-none">
          <input
            matInput
            type="text"
            [(ngModel)]="confirmValue"
            placeholder="Type here" />
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        !messagePopupData.code?.isMultiple &&
        messagePopupData.code?.isOptionalTextbox
      ">
      <mat-form-field
        appearance="outline"
        class="mat-md w-full input-wrapper-none">
        <input
          matInput
          type="text"
          [(ngModel)]="confirmValue"
          placeholder="Type here" />
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="messagePopupData.code?.isForDownload">
      <div class="ibc-body-text-large-bold confirmation-message">
        {{ popupMessages.downloadFileText }}
      </div>
      <mat-radio-group [(ngModel)]="fileType" class="download-file">
        <mat-radio-button value="csv">
          <span class="ibc-body-text-medium"> CSV </span>
        </mat-radio-button>
        <mat-radio-button value="excel">
          <span class="ibc-body-text-medium"> Excel </span>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </div>

  <div class="popup-footer" *ngIf="messagePopupData.code?.isFooterRequired">
    <button
      class="cursor ibc-medium-button {{
        messagePopupData.code?.isBulk
          ? 'ibc-caution-secondary-button'
          : 'ibc-secondary-default-button'
      }}"
      (click)="closePopup()">
      <span class="ibc-body-text-regular">{{ getCancelButtonName() }}</span>
    </button>
    <ng-container
      *ngIf="messagePopupData.code?.isForDownload; else otherActions">
      <button
        class="ibc-medium-button ibc-primary-default-button"
        [ngClass]="!fileType ? '' : 'cursor'"
        (click)="confirm()"
        [disabled]="!fileType">
        <span class="ibc-body-text-regular">
          {{ popupButtons.Download }}
        </span>
      </button>
    </ng-container>
    <ng-template #otherActions>
      <button
        class="ibc-medium-button {{
          messagePopupData.code?.isBulk
            ? 'ibc-caution-default-button'
            : 'ibc-primary-default-button'
        }}"
        [ngClass]="
          messagePopupData.code?.isMultiple &&
          confirmValue !== popupButtons.Delete
            ? ''
            : 'cursor'
        "
        *ngIf="!isDeleteAction"
        (click)="confirm()"
        [disabled]="
          messagePopupData.code?.isMultiple &&
          confirmValue !== popupButtons.Delete
        ">
        <span class="ibc-body-text-regular">
          {{
            messagePopupData.code?.isMultiple ||
            messagePopupData.code?.isForSearch
              ? popupButtons.Delete
              : messagePopupData.code?.isForDiscardChanges
                ? popupButtons.Discard
                : popupButtons.Yes
          }}
        </span>
      </button>
      <button
        class="ibc-medium-button {{
          messagePopupData.code?.isBulk
            ? 'ibc-caution-default-button'
            : 'ibc-primary-default-button'
        }}"
        [ngClass]="
          messagePopupData.code?.isMultiple &&
          confirmValue !== popupButtons.Delete
            ? ''
            : 'cursor'
        "
        *ngIf="isDeleteAction"
        (click)="confirm()"
        [disabled]="
          messagePopupData.code?.isMultiple &&
          normalizeText(confirmValue) !== normalizeText(requiredBulkDeleteText)
        ">
        <span class="ibc-body-text-regular">{{ popupButtons.Delete }}</span>
      </button>
    </ng-template>
  </div>
</div>
