import { FormConfig, Widget } from 'src/app/shared/models/dynamic.model';
import {
  ComponentMapping,
  IManifestCriteriaData,
  IManifestDetailsData,
  IManifestInformationData,
  IManifestRequiredBagData,
  IManifestRequiredCriteriaData,
  ManifestBagGridColumnData,
  TableColumns,
  TimelineDataItem,
} from '../../model/manifest.model';
import { CriteriaComponent } from './manifest-information/criteria/criteria.component';
import { searchDataParam } from 'src/app/shared/utility/constants';

export const widgets: Widget[] = [
  {
    id: 1,
    title: 'Number of Bags',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'bagNumbers',
    name: 'noOfBags',
  },
  {
    id: 2,
    title: 'Number of Shipments',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'hawbNumbers',
    name: 'noOfHawbs',
  },
  {
    id: 3,
    title: 'Total Weight',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'weight',
    name: 'totalWeight',
  },
];

/* Manifest Information Form Fields Configuration */
export const manifestInfoFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'manifestDate',
      type: 'input',
      label: 'Manifest Date',
      required: true,
      requiredError: 'Manifest Date should be in MM-DD-YYYY',
    },
    {
      id: 'originName',
      type: 'input',
      label: 'Origin',
      required: true,
      requiredError: 'Origin is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'origin',
      type: 'input',
      label: 'Origin',
    },
    {
      id: 'destinationName',
      type: 'input',
      label: 'Destination',
      required: true,
      requiredError: 'Destination is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destination',
      type: 'input',
      label: 'Destination',
    },
    {
      id: 'agentName',
      type: 'input',
      label: 'Agent',
      minValue: 1,
      maxValue: 126,
    },
    {
      id: 'agentId',
      type: 'input',
      label: 'Agent',
    },
    {
      id: 'mawbExt',
      type: 'input',
      label: 'MAWB',
      minValue: 11,
      maxValue: 11,
      minError: '11 numbers is required',
      allowNumeric: true,
    },
    {
      id: 'mawbId',
      type: 'input',
      label: 'MAWB',
    },
    {
      id: 'airlineName',
      type: 'input',
      label: 'Airline',
      isDisableField: true,
    },
    {
      id: 'airlineId',
      type: 'input',
      label: 'Airline',
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight Number',
      requiredError: 'Flight Number is required',
      isDisableField: true,
    },
    {
      id: 'flightAirlineName',
      type: 'input',
      label: 'Flight Airline Name',
    },
    {
      id: 'flightAirlineId',
      type: 'input',
      label: 'Flight Data',
    },
    {
      id: 'manifestId',
      type: 'input',
      label: 'Manifest',
      isDisableField: true,
    },
    {
      id: 'information',
      type: 'input',
      label: 'Information',
    },
  ],
};

/* Manifest Timeline Configuration */
export const timelineData: TimelineDataItem[] = [
  {
    date: '3/21/2024',
    time: '11:11:11',
    event: 'Manifest updated',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Manifest criteria updated',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Notes added',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Manifest created',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Bag details edited',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Bag attachment added',
  },
];

/* Criteria Form Fields Configuration */
export const manifestCriteriaFormfieldsConfig: FormConfig = {
  fields: [
    { id: 'minHawbValue', type: 'input', label: 'Minimum HAWB value($)' },
    { id: 'maxHawbValue', type: 'input', label: 'Maximum HAWB value ($)' },
    { id: 'minBagWeight', type: 'input', label: 'Minimum Bag weight(lb)' },
    { id: 'maxBagWeight', type: 'input', label: 'Maximum Bag weight(lb)' },
    { id: 'clearanceType', type: 'input', label: 'Clearance Type' },
    { id: 'clearanceTypeCode', type: 'input', label: 'Clearance Type' },
    { id: 'airline', type: 'input', label: 'Airline' },
    { id: 'airlineId', type: 'input', label: 'Airline' },
    { id: 'serviceTypeGroup', type: 'input', label: 'Service Type' },
    { id: 'serviceTypeGroupCode', type: 'input', label: 'Service Type' },
    { id: 'customer', type: 'input', label: 'Customer' },
    { id: 'customerId', type: 'input', label: 'Customer' },
    { id: 'objectGroup', type: 'input', label: 'Object Group' },
    { id: 'objectGroupId', type: 'input', label: 'Object Group' },
    { id: 'facilityType', type: 'input', label: 'Final Facility' },
    { id: 'facilityTypeId', type: 'input', label: 'Final Facility' },
    { id: 'hawbFinalDestination', type: 'input', label: 'Final Destination' },
    { id: 'hawbFinalDestinationId', type: 'input', label: 'Final Destination' },
    { id: 'isDirty', type: 'input', label: 'isDirty' },
    { id: 'isValid', type: 'input', label: 'isValid' },
  ],
};

/* Component Mappings Configuration */
export const componentMappings: ComponentMapping[] = [
  { title: 'Criteria', componentType: CriteriaComponent },
  { title: 'Notes', componentType: '' },
  { title: 'Events', componentType: '' },
  { title: 'Attachments', componentType: '' },
  { title: 'Details', componentType: '' },
];

/* Piece Grid Input Row Data Configuration */
export const bagsGridInputRowData: ManifestBagGridColumnData[] = [
  {
    bagId: '',
    bagExt: '',
    weight: '',
    packagingType: '',
    pieces: 0,
    isInputRow: true,
  },
];

/* Bag Table Grid Columns Configuration */
export const bagTableGridColumns = [
  { key: 'bagExt', label: 'Bag' },
  { key: 'weight', label: 'Weight (lb)' },
  { key: 'packagingType', label: 'Packaging' },
  { key: 'pieces', label: 'Pieces' },
];

export const defaultManifestCriteriaData = (): IManifestCriteriaData => ({
  minHawbValue: '',
  maxHawbValue: '',
  minBagWeight: '',
  maxBagWeight: '',
  clearanceType: '',
  clearanceTypeCode: '',
  airline: '',
  airlineId: 0,
  serviceTypeGroup: '',
  serviceTypeGroupCode: '',
  customer: '',
  customerId: 0,
  objectGroupId: '',
  facilityType: '',
  facilityTypeId: 0,
  hawbFinalDestination: '',
  hawbFinalDestinationId: 0,
  isDirty: false,
  isValid: true,
});

export const defaultRequiredManifestCriteriaData =
  (): IManifestRequiredCriteriaData => ({
    minHawbValue: '',
    maxHawbValue: '',
    minBagWeight: '',
    maxBagWeight: '',
    clearanceTypeCode: '',
    airlineId: 0,
    serviceTypeGroupCode: '',
    customerId: 0,
    objectGroupId: '',
    facilityTypeId: 0,
    hawbFinalDestinationId: 0,
  });

export const defaultManifestBagData = (): IManifestRequiredBagData[] => [
  {
    bagExt: '',
    weight: '',
    noOfPieces: 0,
    packaging: '',
    action: '',
  },
];

export const defaultManifestInfoData = (): IManifestDetailsData => ({
  manifestDate: '',
  originName: '',
  origin: 0,
  destinationName: '',
  destination: 0,
  agentName: '',
  agentId: 0,
  mawbExt: 0,
  airline: '',
  flightNumber: '',
  manifestId: 0,
  information: '',
});

export const defaultManifestInformationData = (): IManifestInformationData => ({
  manifestDate: '',
  origin: 0,
  destination: 0,
  mawbExt: 0,
  airlineName: '',
  information: '',
  flightNumber: '',
  manifestId: 0,
  details: {
    mawbId: 0,
  },
  notes: {
    ibc: [],
    customer: [],
  },
  criteria: defaultRequiredManifestCriteriaData(),
  bags: defaultManifestBagData(),
});

export const criteriaRequiredKeys = [
  'minHawbValue',
  'maxHawbValue',
  'minBagWeight',
  'maxBagWeight',
  'clearanceTypeCode',
  'airlineId',
  'serviceTypeGroupCode',
  'customerId',
  'facilityTypeId',
  'hawbFinalDestinationId',
  'objectGroupId',
];

export const manifestDetailRequiredKeys = [
  'manifestDate',
  'origin',
  'destination',
  'mawbExt',
  'airlineName',
  'information',
  'flightNumber',
  'manifestId',
  'agentId',
  'flights',
  'agentName',
];

// manifest summary
export const searchFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'manifestDate',
      type: 'input',
      label: 'Manifest Date',
      minValue: 1,
      maxValue: 11,
      allowNumeric: true,
    },
    {
      id: 'manifestNo',
      type: 'input',
      label: 'Manifest Number',
      minValue: 1,
      maxValue: 14,
      allowNumeric: true,
    },
    {
      id: 'originId',
      type: 'input',
      label: 'Origin',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destinationId',
      type: 'input',
      label: 'Destination',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'agentName',
      type: 'input',
      label: 'Agent Name',
    },
    {
      id: 'agentCode',
      type: 'input',
      label: 'Agent Number',
    },
    {
      id: 'airlineCode',
      type: 'input',
      label: 'Airline Number',
    },
    {
      id: 'mawbExt',
      type: 'input',
      label: 'MAWB',
      allowNumeric: true,
      minValue: 1,
      maxValue: 14,
    },
    {
      id: 'airlineName',
      type: 'input',
      label: 'Airline',
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight',
      minValue: 1,
      maxValue: 6,
      allowAlphaNumeric: true,
    },
  ],
};

export const gridSummaryColumns: TableColumns[] = [
  {
    isSelected: true,
    defaultView: true,
    isEditable: false,
    key: 'date',
    label: 'Date',
    value: 'date',
  },
  {
    key: 'manifestNo',
    label: 'Manifest Number',
    value: 'manifestNo',
    isSelected: true,
    defaultView: true,
    isEditable: false,
  },
  {
    value: 'origin',
    isSelected: true,
    defaultView: true,
    key: 'origin',
    label: 'Origin',
    isEditable: false,
  },
  {
    defaultView: true,
    isEditable: false,
    key: 'destination',
    label: 'Destination',
    value: 'destination',
    isSelected: true,
  },
  {
    value: 'noOfBags',
    isEditable: false,
    isSelected: true,
    defaultView: true,
    key: 'noOfBags',
    label: 'Bags',
  },
  {
    isEditable: false,
    value: 'agentCode',
    isSelected: true,
    key: 'agentCode',
    label: 'Agent Code',
    defaultView: true,
  },
  {
    key: 'mawbExt',
    label: 'MAWB',
    isSelected: true,
    value: 'mawbExt',
    defaultView: true,
    isEditable: false,
  },
  {
    key: 'airlineCode',
    label: 'Airline Code',
    value: 'airlineCode',
    isSelected: true,
    defaultView: true,
    isEditable: false,
  },
  {
    isSelected: true,
    defaultView: true,
    isEditable: false,
    key: 'flightNumber',
    label: 'Flight',
    value: 'flightNumber',
  },
  {
    isSelected: false,
    defaultView: false,
    isEditable: false,
    key: 'agentName',
    label: 'Agent Name',
    value: 'agentName',
  },
];

export const defaultGridDisplayColumns = [
  'select',
  'date',
  'manifestNo',
  'origin',
  'destination',
  'noOfBags',
  'agentCode',
  'mawbExt',
  'airlineCode',
  'flightNumber',
  'agentName',
  'action',
];

export const ManifestExcelHeaders: string[] = [
  'Date',
  'Manifest Number',
  'Origin',
  'Destination',
  'Bags',
  'Agent Code',
  'MAWB',
  'Airline',
  'Flight',
  'Agent Name',
];

export const defautPagination = {
  fromIndex: 0,
  toIndex: 10,
  sortingOrder: searchDataParam.desc,
  sortingField: searchDataParam.manifestId,
};

export const reduceWidthForFields = {
  manifestFlightNumber: 16,
  manifestFlightNumberHelp: 12,
};

export const fieldControlNames = {
  manifestFlightNumber: 'manifest-flightNumber',
  manifestFlightNumberHelp: 'manifest-flightNumber-help',
};
