import { Component, Inject, OnInit } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalPopupMessages, popupButtons } from '../../utility/constants';

@Component({
  selector: 'app-message-popup',
  standalone: true,
  imports: [CommonModule, MaterialModule, SvgIconComponent],
  templateUrl: './message-popup.component.html',
  styleUrl: './message-popup.component.scss',
})
export class MessagePopupComponent implements OnInit {
  public messagePopupData: any;
  public confirmValue!: string;
  public popupMessages = ModalPopupMessages;
  public isDeleteAction = false;
  public deleteText = ModalPopupMessages.deleteConfirmationText;
  public requiredBulkDeleteText = '';
  public popupButtons = popupButtons;
  public fileType = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ref: MatDialogRef<MessagePopupComponent>
  ) {
    this.confirmValue = '';
  }

  ngOnInit(): void {
    this.messagePopupData = this.data;
    this.deleteText = ModalPopupMessages.deleteConfirmationText;
    this.updateDeleteConfirmationText();
  }

  public getCancelButtonName() {
    let buttonName = popupButtons.Cancel;
    if (
      this.messagePopupData.code?.isMultiple ||
      this.messagePopupData.code?.isForDownload ||
      this.messagePopupData.code?.isForSearch ||
      this.messagePopupData.code?.isForPublish
    ) {
      buttonName = popupButtons.Cancel;
    } else if (this.messagePopupData.code?.isForDiscardChanges) {
      buttonName = popupButtons.ContinueEdit;
    } else if (!this.messagePopupData.code?.isMultiple) {
      buttonName = popupButtons.No;
    }
    return buttonName;
  }

  public confirm() {
    if (
      !this.messagePopupData.code?.isMultiple &&
      this.messagePopupData.code?.isOptionalTextbox
    ) {
      this.ref.close({ popupClose: true, comments: this.confirmValue });
    } else if (
      !this.messagePopupData.code?.isMultiple &&
      this.messagePopupData.code?.isForDownload
    ) {
      this.ref.close({ popupClose: true, fileType: this.fileType });
    } else {
      this.ref.close(true);
    }
    this.defaultData();
  }

  public closePopup() {
    this.ref.close(false);
    this.defaultData();
  }

  public normalizeText(text: string): string {
    return text.toLowerCase().replace(/\s+/g, '');
  }

  private updateDeleteConfirmationText() {
    if (this.messagePopupData.code?.isBulk) {
      const message = this.messagePopupData.code?.message;
      const regexPattern = /\b\d+\b/;
      const match = message.match(regexPattern);
      if (match && match.length > 0) {
        const number = parseInt(match[0]);
        this.deleteText = `If so, type "Delete ${number}" and click Delete.`;
        this.isDeleteAction = true;
        this.requiredBulkDeleteText = `Delete ${number}`;
      } else {
        this.isDeleteAction = false;
        this.requiredBulkDeleteText = '';
      }
    } else {
      this.defaultData();
    }
  }

  private defaultData() {
    this.isDeleteAction = false;
    this.deleteText = ModalPopupMessages.deleteConfirmationText;
    this.requiredBulkDeleteText = '';
  }
}
