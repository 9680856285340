<ng-container *ngIf="!isStaticSvg; else staticSvg">
  <ng-container *ngFor="let icon of iconList">
    <div
      class="svg-icon {{icon.iconClass}}"
      [ngClass]="icon.disabled ? 'icon-bg' : 'icon-outline cursor'"
      (click)="iconClick(icon)"
      (keydown)="$event.stopPropagation()">
      <svg
        [attr.width]="icon.width"
        [attr.height]="icon.height"
        [class]="icon.iconClass"
        [ngClass]="icon.disabled ? 'disabled' : ''"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [matTooltip]="icon.toolTip"
        matTooltipPosition="above"
       >
        <path [attr.d]="icon.path" class="{{icon.iconClass}}"/>
        <path *ngIf="icon.name === 'print'" [attr.d]="icon.pathAdditional" />
        <rect *ngIf="icon.rect" [attr.x]="icon.rect.x" [attr.y]="icon.rect.y" [attr.width]="icon.rect.width" [attr.height]="icon.rect.height" [attr.rx]="icon.rect.rx" [attr.fill]="icon.rect.fill"/>
      </svg>
    </div>
  </ng-container>
</ng-container>
<ng-template #staticSvg>
  <svg
    [attr.width]="staticList.width"
    [attr.height]="staticList.height"
    [class]="staticList.iconClass"
    (click)="staticIconClick(staticList)"
    (keydown)="$event.stopPropagation()">
    <path
      *ngFor="let data of staticList.path; let i = index"
      [attr.d]="data"
      [class]="staticList.pathClass + (i + 1)"
      [attr.opacity]="
        staticList.name === 'sort'
          ? i === 0
            ? condition === 'asc'
              ? '1'
              : '0.2'
            : condition === 'desc'
              ? '1'
              : '0.2'
          : 1
      " />
    <rect *ngIf="staticList.rect" [attr.x]="staticList.rect.x" [attr.y]="staticList.rect.y" [attr.width]="staticList.rect.width" [attr.height]="staticList.rect.height" [attr.rx]="staticList.rect.rx" [attr.fill]="staticList.rect.fill"/>
  </svg>
</ng-template>
