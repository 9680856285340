import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  BagCriteriaState,
  BagMiscellaneousState,
  BagPieceState,
  BagsListState,
  BagInformationState,
  BagInfoState,
  PieceBagState,
} from '../features/components/bag/store/bag.state';
import {
  RolesState,
  DispCodesState,
  PODState,
  MiscellaneousState,
  HawbInformationState,
  PieceState,
  CommodityState,
  MiscellaneousCommodityState,
  HawbsListState,
  ConsigneeShippingState,
} from '../features/components/hawb/store/hawb.state';
import { ResetAllState } from '../features/components/hawb/store/reset.states';
import { NotesSectionState } from './components/notes/store/notes.state';
import { AttachmentState } from './components/attachments/store/attachments.state';
import { EventsState } from './components/events/store/events.state';
import { BagResetAllState } from '../features/components/bag/store/reset.states';
import {
  ManifestBagListState,
  ManifestCriteriaState,
  ManifestDetailsState,
  ManifestInformationState,
  ManifestsListState,
} from '../features/components/manifest/store/manifest.state';
import { ManifestResetAllState } from '../features/components/manifest/store/reset.states';
import { FlightMiscellaneousState, FlightState, ManifestsMawbState, MawbDetailsState, MawbInformationState, MawbManifestState, MawbMiscellaneousState } from '../features/components/mawb/store/mawb.state';
import { MawbsListState } from '../features/components/mawb/store/mawb.state';
import { MawbResetAllState } from '../features/components/mawb/store/mawb.reset';
import { AttachmentAdvanceSearchModalState, BagAdvanceModalState, CommodityAdvanceModalState, ConsigneeAdvanceModalState, CrudAdvanceModalState, DetailAdvanceModalState, ManifestAdvanceModalState, MawbAdvanceModalState, MiscAdvanceState, MiscellaneousAdvanceModalState, NoteAdvanceModalState, PieceModalState, ResetHawbAdvanceSearchState, RoleAdvanceModalState, StatusAdvanceModalState, WidgetAdvanceState } from './components/advanced-search/store/advance-search.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      BagCriteriaState,
      BagMiscellaneousState,
      BagPieceState,
      RolesState,
      DispCodesState,
      PODState,
      AttachmentState,
      MiscellaneousState,
      HawbInformationState,
      PieceState,
      CommodityState,
      MiscellaneousCommodityState,
      ResetAllState,
      BagResetAllState,
      EventsState,
      HawbsListState,
      ManifestsListState,
      BagsListState,
      BagInformationState,
      BagInfoState,
      PieceBagState,
      NotesSectionState,
      ManifestCriteriaState,
      ManifestDetailsState,
      ManifestBagListState,
      ManifestResetAllState,
      ManifestInformationState,
      MawbDetailsState,
      MawbMiscellaneousState,
      MawbManifestState,
      FlightState,
      MawbsListState,
      ConsigneeShippingState,
      MawbResetAllState,
      FlightMiscellaneousState,
      MawbInformationState,
      ManifestsMawbState,
      WidgetAdvanceState,
      PieceModalState,
      AttachmentAdvanceSearchModalState,
      DetailAdvanceModalState,
      CommodityAdvanceModalState,
      RoleAdvanceModalState,
      NoteAdvanceModalState,
      MiscellaneousAdvanceModalState,
      CrudAdvanceModalState,
      BagAdvanceModalState,
      ManifestAdvanceModalState,
      MawbAdvanceModalState,
      ConsigneeAdvanceModalState,
      StatusAdvanceModalState,
      MiscAdvanceState,
      ResetHawbAdvanceSearchState
    ]),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [],
})
export class StoreModule {}
