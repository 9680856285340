export const ErrorHandlerConstants = {
  Error_400: 'Element Not Found Please Provide Valid Trade Id',
  Error_500:
    'Sorry, Operation could not be completed at this time.\n Back-end returned with an Internal Server Error. Please contact customer support',
  Default_Error:
    'Sorry, Operation could not be completed at this time.\n Please contact customer support',
  Initial_Error_Text: 'Sorry, Operation could not be completed. \n Error: \n',
  String_Declare: 'string',
  Connective_Error:
    'Sorry, we are having connectivity issues, Please contact our Pulse customer engagement for more help',
  Error_502:
    'Sorry, Operation could not be completed at this time.\n Back-end returned with an Timeout Error. Please contact customer support',
  SessionTimout: 'Session timedout, Please login again',
  InvalidPackageType: 'Please enter a valid packaging type',
};

export const SnackBar = {
  Close: 'Close',
};

export const HttpSignatures: HttpSignature = {
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Get: 'GET',
};

export interface HttpSignature {
  [name: string]:
    | 'DELETE'
    | 'GET'
    | 'HEAD'
    | 'JSONP'
    | 'OPTIONS'
    | 'POST'
    | 'PUT';
}

export enum StatusCodes {
  Success = 200,
  SuccessfulRequest = 204,
  BadRequest = 400,
  Unauthorised = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}

export const ModalPopupMessages = {
  AddComment: 'Add Comment',
  NotSignedUpServiceType:
    'You have not signed up for this service. Would you still like to go ahead and use this service?',
  InvalidEntry: 'Invalid Entry',
  HAWBValidation: 'Shipment should always have at least one piece',
  Validation: 'Validation',
  FillMandatoryFields:
    'Changes could not be saved. Please check your input and try again',
  AddHAWB: 'Would you like to add a Shipment for',
  DeleteHawb: (messageType: string) =>
    `Would you like to delete the selected ${messageType}?`,
  NoUserExist: 'No customer exists for the input',
  Warning: 'Warning',
  Error: 'error',
  Reset: 'You have unsaved changes. Would you like to go back?',
  ResetPiece: 'Reset Piece Information',
  ResetFlight: 'Reset Flight Information',
  ResetCommodity: 'Reset Commodity Information',
  close: 'close',
  resetHeader: 'Reset',
  DeletePiece: 'Are you sure you want to delete this piece?',
  ChangeSuccess: 'Changes have been saved successfully',
  success: 'success',
  error: 'error',
  ChangeResetSuccess: 'Changes reset successfully',
  SaveChanges: 'Would you like to save the changes?',
  SaveCommodityInfo: 'Save Commodity Information',
  save: 'save',
  DeleteCommodity: 'Are you sure you want to delete this commodity?',
  MandatoryFields:
    'Please fill out all mandatory fields and check all inputs before proceeding',
  Block: 'Block',
  Unblock: 'Unblock',
  BlockMessage: 'Are you sure you want to block the Shipment ?',
  UnblockMessage: 'Are you sure you want to unblock the Shipment ?',
  SavePiece: 'Save Piece Information',
  DeleteStatusCode: 'delete the Status Code',
  StatusCode: 'Status Code',
  DeletePOD: 'delete the POD',
  POD: 'POD',
  unlinkPiece: 'Are you sure you want to unlink this piece(s)?',
  linkPiece: 'Piece added successfully',
  unlink: 'Unlink Piece(s)',
  unlinkSuccess: 'Piece removed from the bag',
  noChanges: 'No Changes were made to save',
  noMoreFlights: 'Allowed only to add a maximum of 3 flights only',
  cancel:
    'You have made changes. Are you sure you want to discard the changes?',
  flightInfo: 'Flight Maximum Limit',
  noChangeSave: 'No Changes',
  notesInformationText:
    'Notes are a useful record of key information. Here, user can add important notes relevant to the Shipment. Remember, clear and concise notes help streamline communication and tracking throughout the Shipment lifecycle',
  statusCodeInformationText:
    'The status code represents the current state or condition of the Shipment. Choose the appropriate status code that accurately reflects the progress or status of the shipment. Consistent use of status codes aids in effective tracking and management of shipments.',
  podInformationText:
    'The Proof of Delivery (POD) functionality encompasses various delivery confirmation methods to ensure a comprehensive and adaptable system for verifying the receipt of shipments.',
  attachmentsInformationText:
    'Attachments serve as supporting documentation for the Shipment, providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Shipment record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the Shipment lifecycle.',
  miscellaneousInformationText:
    'This section captures additional details and miscellaneous information related to the Shipment. Here, user can include relevant details that contribute to a comprehensive understanding of the Shipment. Clear and accurate miscellaneous information enhances communication and clarity throughout the Shipment lifecycle',
  pieceAttachmentsInformationText:
    'Attachments serve as supporting documentation for the Piece providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Piece record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the piece lifecycle.',
  commodityAttachmentsInformationText:
    'Attachments serve as supporting documentation for the Commodity, providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Commodity record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the Commodity lifecycle.',
  pieceNotesInformationText:
    'Notes are a useful record of key information. Here, user can add important notes relevant to the piece. Remember, clear and concise notes help streamline communication and tracking.',
  commodityNotesInformationText:
    'Notes are a useful record of key information. Here, user can add important notes relevant to the commodity. Remember, clear and concise notes help streamline communication and tracking.',
  commodityMiscellaneous:
    'This section captures additional details and miscellaneous information related to the commodity. Here, user can include relevant details that contribute to a comprehensive understanding of the commodity. Clear and accurate miscellaneous information enhances communication and clarity.',
  resetBag: 'Reset Bag Information ?',
  deleteNote: 'Are you sure you want to delete the note ?',
  app: 'App',
  ui: 'UI',
  bagNotesText:
    'Notes are a useful record of key information, and the sources of that information. Here, user can add important notes relevant to the Bag. Remember, clear and concise notes help streamline communication and tracking throughout the Bag lifecycle',
  loreum:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodtempor incididunt ut labore et dolore magna aliqua. Ut enim ad minimveniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  bagAttachment:
    'Attachments serve as supporting documentation for the Bag, providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Bag record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the Bag lifecycle.',
  bagMiscellaneous:
    'This section captures additional details and miscellaneous information related to the Bag. Here, user can include relevant details that contribute to a comprehensive understanding of the Bag. Clear and accurate miscellaneous information enhances communication and clarity throughout the Bag lifecycle.',
  unlinkBagManifest: 'Are you sure you want to unlink this bag(s)?',
  unlinkBag: 'Unlink Bag(s)',
  unlinkBagSuccess: 'Bag removed from the Manifest',
  linkBagSuccess: 'Bag added successfully',
  resetManifest: 'Reset Manifest Information ?',
  deleteConfirmationText: 'If so, type "Delete" and click Delete',
  clearConfirmationText: 'If so, type "Clear" and click Clear',
  PieceShipmentAdd: 'Piece already in a different shipment',
  commodityAdd: 'Commodity added successfully',
  FillAgentMandatoryField:
    'Agent is required. Please fill in the Agent before publishing the Manifest.',
  savedSearchesHeader: 'Saved Searches',
  recentSearchesHeader: 'Recent Searches',
  saveAsNewSearchHeader: 'Save Search',
  pinSearch: 'Pin Search',
  mawbMiscellaneousMessage:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
  updateSearch: 'Update Search',
  searchDelete: 'Would you really like to delete the Search',
  clearRecentSearchContent: 'Do you want to delete all Recent Searches?',
  FillHawbMandatoryField:
    'Please fill out all mandatory fields before proceeding',
  resetMAWB: 'Reset MAWB Information ?',
  restMawbInfo: '‘Would you like to cancel the MAWB creation and go back?',
  BulkDeleteHeader: 'Bulk Delete ?',
  singleDeleteHeader: (headerName: string) => `Delete ${headerName} ?`,
  bulkDeleteMessage: (count: number | string, itemName: string) =>
    `Do you want to delete these ${count} ${itemName}?`,
  singleDeleteMessage: (itemName: string) =>
    `Do you want to delete the ${itemName} `,
  singleDeleteSuccessMessage: (itemName: string) =>
    `Selected ${itemName} deleted successfully`,
  bulkDeleteSuccessMessage: (count: number | string, itemName: string) =>
    `Selected ${count} ${itemName} deleted successfully`,
  downloadFileText: 'Select file type',
  BagManifestDeleteSuccess: (itemName: string) =>
    `${itemName} removed from the Manifest`,
  PieceCommodityDeleteSuccess: (itemName: string) =>
    `${itemName} removed from the shipment`,
  PieceBagDeleteSuccess: (itemName: string) =>
    `${itemName} removed from the Bag`,
  addShipment: 'Add Shipment?',
  downloadSuccess: (itemName: string) => `${itemName} download successful`,
  mawbExist: 'Mawb already exists. Please enter another one',
  resetAgentInfo: '‘Would you like to cancel the Agent creation and go back?',
  resetAgent: 'Reset Agent Information ?',
  searchSuccess: (itemName: string, action: string, count?: number) =>
    count
      ? `${count} ${itemName} ${action} successfully`
      : `${itemName} ${action} successfully`,
  searchLimitWarning: (itemName: string, count: number) =>
    `${itemName} search limit reached: Maximum of ${count} searches allowed`,
  alreadyExists: (itemName: string) =>
    `${itemName} already exists. Please enter another one`,
  linkManifestSuccess: 'Manifest added successfully',
  unlinkManifestMawb: 'Are you sure you want to unlink this Manifest(s)?',
  unlinkManifest: 'Unlink Manifest(s)',
  ManifestMawbDeleteSuccess: (itemName: string) =>
    `${itemName} removed from the MAWB`,
  actionCancel: 'Action cancelled',
  unlinkFlightMawb: 'Are you sure you want to delete this Flight(s)?',
  unlinkFlight: 'Delete Flight(s)',
  addFlightSuccess: 'Flight added successfully',
  MandatoryFlightFields:
    'Please fill out all mandatory fields and check all inputs before proceeding',
  DiscardChangesHeader: 'Discard changes',
  DiscardChangesContent: 'Are you sure you want to discard the changes ?',
  PublishMawbMessage: (itemName: string) =>
    `Would you like to publish MAWB ${itemName} ?`,
  PublishMultipleMawbMessage: (count: number) =>
    `Would you like to publish ${count} MAWBs?`,
  restMawb: 'Reset MAWB Information ?',
  flightDeleteSuccess: (itemName: string) =>
    `${itemName} removed from the MAWB`,
};

export const actionText = {
  deleted: 'deleted',
  pinned: 'pinned',
  unpinned: 'unpinned',
  saved: 'saved',
  update: 'updated',
  pin: 'Pin',
  Saved: 'Saved',
  published: 'published',
};

export const searchLimitCount = {
  pin: 10,
  saved: 1000,
};

export const SingleDeleteHeaderText = {
  Shipment: 'Shipment',
  Bag: 'Bag',
  Manifest: 'Manifest',
  Search: 'Search',
  Piece: 'Piece',
  Commodity: 'Commodity',
  Mawb: 'Mawb',
  RecentSearch: 'Recent search',
  RecentSearches: 'Recent searches',
  savedSearch: 'Saved search',
  savedSearches: 'Saved searches',
  Flight: 'Flight',
  Flights: 'Flights',
};

export const BulkDeleteItemNames = {
  Shipments: 'Shipments',
  Bags: 'Bags',
  Manifests: 'Manifests',
  Searches: 'Searches',
  Pieces: 'Pieces',
  Commodities: 'Commodities',
  Mawbs: 'MAWBs',
  Flights: 'Flights',
  Agents: 'Agents',
  AgentInformation: 'Agent Information',
};

export const DownloadItemNames = {
  Shipments: 'Shipment(s)',
  Bags: 'Bag(s)',
  Manifests: 'Manifest(s)',
  Mawbs: 'MAWB(s)',
};

export const popupButtons = {
  Clear: 'Clear',
  Cancel: 'Cancel',
  Delete: 'Delete',
  Download: 'Download',
  Yes: 'Yes',
  No: 'No',
  Discard: 'Discard Changes',
  ContinueEdit: 'Continue Editing',
};

export const ActionIcons = {
  close: 'close',
  edit: 'edit',
  save: 'save',
  reset: 'reset',
  Reset: 'Reset',
  refresh: 'refresh',
  filter: 'filter',
  add: 'add',
  delete: 'delete',
  view: 'view',
  bulkEdit: 'bulk-edit',
  addCom: 'Addcom',
  AddTooltip: 'Add',
  addCommodity: 'addCom',
  download: 'Download',
  downloadFile: 'downloadFile',
  Downloadfile: 'Downloadfile',
  pinSearch: 'pin',
  unPinSearch: 'unpin',
  advanceSearch: 'advancedSearch',
  hamburger: 'hamburger',
  search: 'Search',
  searchSmallCase: 'search',
  default: 'Default',
  selected: 'Selected',
  searchPopupPin: 'searchActionPin',
  searchPopupEdit: 'searchActionEdit',
  searchPopupDelete: 'searchActionDelete',
  searchPopupSave: 'searchActionSave',
  hamburgerRecentListPin: 'hamburgerRecentListPin',
  hamburgerRecentListSave: 'hamburgerRecentListSave',
  hamburgerRecentListDelete: 'hamburgerRecentListDelete',
  rightArrow: 'arrowRight',
  print: 'print',
  bulkview: 'bulk-view',
  fieldClear: 'fieldClear',
  infoCircle: 'infoCircle',
  fetchResult: 'fetchResult',
  details: 'details',
  publish: 'Publish',
};

export const ModeIcons = {
  mode: 'mode',
  edit: 'Edit',
  view: 'View',
  add: 'Add',
  bulkEdit: 'Bulk-Edit',
  smallview: 'view',
  smallEdit: 'edit',
  deleteAPI: 'deleteAPI',
  delete: 'delete',
  MainView: 'MainView',
  resetSelection: 'resetSelection',
};

export const CrudActions = {
  create: 'c',
  update: 'u',
  delete: 'd',
  api: 'API',
  ui: 'UI',
  add: 'a',
  updatePiece: 'update',
};

export const NotesType = {
  ibc: 'ibc',
  customer: 'customer',
};

export const pageType = {
  hawb: 'hawb',
  piece: 'piece',
  commodity: 'commodity',
  bag: 'bag',
  bagPiece: 'bag-piece',
  pieceInfo: 'piece-information',
  commodityInfo: 'commodity-information',
  manifest: 'manifest',
  mawb: 'mawb',
  dataBind: 'dataBind',
  flightInformation: 'flight-information',
  agent: 'agent',
  mawbFlight: 'mawb-flight',
  agentInformation: 'agent-information',
};

export const AttachmentDownloadRequestType = {
  hawb: 'HAWB',
  piece: 'HAWB_PIECE',
  commodity: 'HAWB_COMMODITIES',
  bag: 'BAG',
  manifest: 'MANIFEST',
  mawb: 'MAWBS',
  bagExt: 'Bag_Ext',
  mawbInfo: 'MAWB Information',
};

export const validationErrorMessages = {
  originDestination: 'Origin and Destination should not be the same',
  manifestOriginDestination:
    'Shipment Final Destination and Manifest origin should not be the same',
  minmaxHawbValue:
    'Minimum Shipment value should not be more than the Maximum Shipment value',
  minmaxBagWeight:
    'Minimum Bag Weight should not be more than the Maximum Bag Weight',
  autocompleteError: 'Please select value from dropdown',
  manifestBagCriteriaMatchError: `Bag doesn't meet the criteria set up for the Manifest`,
  manifestAlreadyAdded: 'Bag already in the Manifest.',
  pieceAlreadyPresent: 'Piece already in the bag',
  invalidMawb: 'Invalid MAWB entered',
  invalidFlight: 'Invalid Flight entered',
  pieceinShipment: 'Piece already added to the shipment',
  manifestAfterSearch: 'No Manifests matched your search',
  manifestBeforeSearch: 'Please perform a search to view Manifests',
  pieceBagPackageError:
    'Invalid Packaging Type. Please select a valid packaging type from the following options: B Box, C Crate, G Gaylord, O Other, A Pallet.',
  bagAfterSearch: 'No Bags matched your search',
  bagBeforeSearch: 'Please perform a search to view Bags',
  piecePackageError:
    'Invalid Packaging Type. Please select a valid packaging type from the following options: B Box, C Crate, L Letter, M Metal Film Can, O Other, P IBC Pak, T Tube, A Pallet.',
  resetHawb: 'Reset Shipment Information',
  hawbAfterSearch: 'No Shipments matched your search',
  hawbBeforeSearch: 'Please perform a search to view Shipments',
  minHawbWeightError: 'Please enter value less than to Max HAWB weight',
  maxHawbWeightError: 'Please enter value more than to Min HAWB Weight',
  minBagWeightError: 'Please enter value less than to Max Bag weight',
  maxBagWeightError: 'Please enter value more than to Min Bag Weight',
  minHawbValueError: 'Please enter value less than to Max Shipment Value',
  maxHawbValueError: 'Please enter value more than to Min Shipment Value',
  tenCharactersReq: '10 characters required',
  manifestAlreadyPresent: 'Manifest already in the MAWB',
  name: 'name',
  clearanceTypeError:
    'Invalid Clearance type. Please select a valid Clearance type from the following options: C - CFS, E - ECCF and 6 - CFS Type 86',
  publishCriteriaMismatchError:
    'MAWB cannot be published due to the following issues: Clearance agent not assigned, no flights linked, and Pieces from a blocked shipment are present in the linked Manifest',
  publishPartialSelectedMismatchError: (invalidCount: number) =>
    `${invalidCount} MAWBs do not fulfill the publish criteria. Would you like to still proceed with publishing the remaining MAWBs?`,
  publishPartialSelectedSingleMismatchError: (invalidCount: number) =>
    `${invalidCount} MAWB do not fulfill the publish criteria. Would you like to still proceed with publishing the remaining MAWB?`,
  publishClearanceAgent: 'MAWB cannot be published without a Clearance Agent',
  publishNoFlights: 'MAWB cannot be published without flight',
  publishShipmentBlocked:
    'MAWB cannot be published due to pieces from a blocked shipment in the linked Manifest',
  publishSelectedAllFailed:
    'None of the selected MAWBs meet the publish criteria',
  updateMawb: 'Update Mawb?',
};

export const MasterLookup = {
  agentInfo: 'agentInfo',
  clearanceType: 'clearanceType',
  airlineCode: 'airlineCode',
  serviceTypeGroups: 'serviceTypeGroups',
  customerId: 'customerId',
  customerGroup: 'customerGroup',
  origin: 'origin',
  destination: 'destination',
  serviceType: 'serviceType',
  packaging: 'packaging',
  finalFacility: 'finalFacility',
  noApiControl: 'originIdVal',
  packagingTypeName: 'packagingTypeName',
  packagingType: 'packagingType',
  clearanceTypeVal: 'clearanceTypeVal',
  clearanceTypeCode: 'clearanceTypeCode',
  originID: 'originID',
  destinationID: 'destinationID',
  airlineName: 'airlineName',
  agentName: 'agentName',
  cargoOperatorName: 'cargoOperatorName',
  firstUsAirportName: 'firstUsAirportName',
  airportCode: 'airportCode',
  attachmentType: 'attachmentType',
  comparisonError: 'comparisonError',
  minHawbValue: 'minHawbValue',
  maxHawbValue: 'maxHawbValue',
  maxBagWeight: 'maxBagWeight',
  minBagWeight: 'minBagWeight',
  hawbFinalDestination: 'hawbFinalDestination',
  hawbFinalDestinationId: 'hawbFinalDestinationId',
};

export const searchDataParam = {
  summary: 'summary',
  desc: 'desc',
  hawbExt: 'hawbExt',
  summaryInfo: 'summary-info',
  hawbId: 'hawbId',
  manifestId: 'manifestId',
  bagExt: 'bagExt',
  information: 'information',
  basicSearch: 'basicSearch',
  bagId: 'bagId',
  mawbExt: 'mawbExt',
  mawbId: 'mawbId',
  flightInformation: 'flight-information',
  asc: 'asc',
};

export const shipmentStatus = {
  block: 'blocked',
  unblock: 'unblocked',
};

export const manifestFlightTootlip = {
  noFlight: 'Flight can be added to the Manifest',
};

export const WidgetValues = {
  noOfBags: 'noOfBags',
  noOfHawbs: 'noOfHawbs',
  totalWeight: 'totalWeight',
  totalNoOfPieces: 'totalNoOfPieces',
  totalValue: 'totalValue',
  noOfManifests: 'noOfManifests',
  noOfFlights: 'noOfFlights',
  lbUnit: ' lb',
  emptyValue: '--',
  singleZero: '0',
  multipleZero: '0.00',
};

export const GridColumnsStorageNames = {
  hawb: 'hawbDisplayColumns',
  bag: 'bagDisplayColumns',
  manifest: 'manifestDisplayColumns',
  mawb: 'mawbDisplayColumns',
  mawbManifest: 'mawbManifestDisplayColumns',
  mawbFlight: 'mawbFlightDisplayColumns',
  agent: 'agentDisplayColumns',
  agentFacility: 'agentFacilityDisplayColumns',
};

export const keyStrokes = {
  enter: 'Enter',
};

export const actionIcons = {
  save: 'saveIcon',
  filter: 'filterIcon',
  search: 'searchIcon',
};

export const OverlayPositions = {
  top: 'top' as const,
  end: 'end' as const,
  center: 'center' as const,
};

export const BasicSearchPlaceholder = {
  mawb: 'Search by Shipment, Piece, Agent Shipment, Customer Reference, Bag, MAWB, Manifest, Piece and Bag Barcodes',
  mawbInfoText:
    'Search for MAWBs by HAWB_Ext, Piece, Agent Shipment, Customer Reference Number, Bag_Ext, MAWB, Manifest, Piece Bar code, Bag Bar code separated by space',
  agents: 'Search by Agent Status, Code, Name, Type and Facility',
  agentInfoText:
    'Search by Agent Status, Code, Name, Type and Facility separated by space',
};

export const gridMessages = {
  noSearch: 'Please perform a search to view',
  noMawbMatch: (itemName: string) => `No ${itemName} matched your search`,
  noRecentSearch: 'No recent search is performed',
  noSavedSearch: 'No search is saved',
};

export const AccordionTabs = {
  Roles: 'Roles',
  ShipperConsginee: 'Shipper & Consignee',
  Miscellaneous: 'Miscellaneous',
  Events: 'Events',
  Attachments: 'Attachments',
  Details: 'Details',
  Notes: 'Notes',
};

export const DataTypes = {
  String: 'string',
};

export const HttpConstSignatures = {
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Get: 'GET',
  json: 'json',
};

export const TableHeaderValues = {
  addCom: 1,
  view: 2,
  edit: 3,
  delete: 4,
  save: 5,
  close: 6,
  add: 1,
};

export const ScrollData = {
  smooth: 'smooth',
  auto: 'auto',
};
export const selectedIdForSummaryTable = {
  Mawb: 'mawbId',
  Agent: 'agentId',
};

export const selectionTableNames = {
  Mawb: 'Mawb',
  MawbManifest: 'Mawb-Manifest',
  MawbFlight: 'Mawb-Flight',
  AgentFacility: 'agent-information',
};

export const InputRowKeys = {
  Mawb: 'mawb',
  MawbManifest: 'manifestNumber',
  MawbFlight: 'airlineCode',
  Agent: 'agentCode',
  AgentFacility: 'facilityCode',
};

export const TableProps = {
  select: 'select',
  action: 'action',
  customize: 'customize',
  inputRow: 'isInputRow',
};

export const fileFormats = {
  csv: 'csv',
  xlsx: 'xlsx',
};

export const fileFormatsType = {
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const dateTimeFormat = {
  dateTime12hours: 'MM-dd-yyyy h:mm:ss a',
  dateTime24hours: 'MM-dd-yyyy_HH-mm-ss',
  dateTime24hoursWithSpace: 'MM-dd-yyyy HH:mm:ss',
};

export const editMessageTemplate = {
  editMessage: (count: number, itemType: string) =>
    `Would you like to edit ${count} ${itemType} at once?`,
};

export const setupAndMaintenanceMessages = {
  view_more: 'View More',
};
export const FetchUrlById = {
  hawbId: 'hawbIds/',
};

export const AttachmentMessages = {
  removeAttachment: 'Remove Attachment',
  removeAtatchmentMessage: `Are you sure you want to remove the attachment ?`,
  deleteAttachment: 'Attachment deleted  successfully',
  AddAttachment: 'Attachment added successfully',
};
export const SvgIconFrom = {
  page: 'Page',
};

export const MessageTemplates = {
  AllItemsSelected: (itemType: string, itemCount: number) =>
    `All ${itemCount} ${itemType} on this page are selected.`,
  SelectAllItems: (itemType: string, totalItemCount: number) =>
    `Select all ${totalItemCount} ${itemType}`,
};

export const popupWidth = {
  downloadPopup: '380px',
  searchPopup: '500px',
  deletePopup: '360px',
};

export const hyperlinkLabels = {
  mawb: 'MAWB',
  agent: 'Code',
};

export const LocalDataStorage = {
  FlightList: 'FlightList',
};

export const downloadInfoPageSectionKeys = {
  mawbInfo: 'mawbInfo',
  mawbmiscellaneous: 'mawbmiscellaneous',
  notes: 'notes',
  events: 'events',
  attachments: 'attachments',
  details: 'details',
  mawbManifests: 'mawbManifests',
  mawbFlights: 'mawbFlights',
};

export const downloadInfoPageSectionHeaders = {
  mawbInfo: 'MAWB Information',
  mawbmiscellaneous: 'Miscellaneous',
  notes: 'Notes',
  events: 'Events',
  attachments: 'Attachments',
  details: 'Details',
  mawbManifests: 'Manifest(s) linked',
  mawbFlights: 'Flight(s) linked',
};
