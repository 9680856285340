import { FormConfig } from '../../models/dynamic.model';

/* MAWB - Customize Columns */
export const MawbColumnsList: string[] = [
  'select',
  'mawb',
  'origin',
  'destination',
  'agentCode',
  'airlineCode',
  'clearanceType',
  'agentName',
  'airlineName',
  'action',
];

/* MAWB - Manifest Customize Columns */
export const MAWBManifestCustomizeColumns: string[] = [
  'select',
  'manifestNumber',
  'date',
  'bags',
  'shipments',
  'pieces',
  'action',
];

/* MAWB - Manifest Customize Columns */
export const MAWBFlightCustomizeColumns: string[] = [
  'select',
  'airlineCode',
  'flightNumber',
  'slacCount',
  'slacWeight',
  'arrivalDate',
  'mawbPart',
  'action',
];

export const AgentColumnList: string[] = [
  'select',
  'agentCode',
  'agentName',
  'agentType',
  'facilities',
  'agentStatus',
  'action',
];

export const AgentFacilityColumnList: string[] = [
  'select',
  'facilityCode',
  'action',
];

export const shipmentCommodityColumnList: string[] = [
  'select',
  'commodityNumber',
  'hsCode',
  'value',
  'description',
  'action',
];

export const shipmentPieceColumnList: string[] = [
  'select',
  'pieceExt',
  'pieceNumber',
  'weight',
  'dimLWH',
  'packagingTypeName',
  'action',
];

export const selectTableMawbManifestFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'searchInput',
      type: 'input',
      label: '',
      minValue: 1,
      maxValue: 25,
      allowNumeric: true,
    },
  ],
};

export const AutocompleteControls = ['airlineCode', 'packagingTypeName'];

export const ValueControls = ['commodityNumber', 'pieceNumber'];

export const InputWithDecimalValidationKeys = ['slacWeight', 'value', 'weight'];

export const InputWithoutValidationKeys = [
  'slacCount',
  'mawbPart',
  'hsCode',
  'description',
];

export const InputWithAlphaNumbers = ['flightNumber'];

export const InputWithAlphaNumbersHyphen = ['pieceExt'];

export const InputWithNumbers = ['dimLWH', 'dimL', 'dimW', 'dimH'];

export const TableControls = {
  airlineCode: 'airlineCode',
  packageType: 'packagingTypeName',
  arrivalDate: 'arrivalDate',
  dimLWH: 'dimLWH',
};

export const ApiControls = {
  airlineCode: 'airlineId',
  packageType: 'packagingType',
};

export const desiredPackageIds = ['B', 'C', 'L', 'M', 'P', 'O', 'A', 'T'];
